import React from "react";
import ItemContactBlock from "./ItemContactBlock";
import {useDispatch, useSelector} from "react-redux";
import {contactsSelector, contactsOnLoadSelector} from "../Store/Selectors";
import {getContacts} from "../Utils/Common";
import {refactContactToDefaultList, setContactInfos, setContactLoading} from "../Store/ContactsReducer";
import LoadingItemsVertical from "./LoadingItemsVertical";
import {NBR_CONTACT_PER_PAGE} from "../Utils/Defines";
import {Link} from "react-router-dom";
import {onClickLoadMoreContact} from "../EventHandler/OnClickEvents";
import ModalInfoContact from "./Modal/ModalInfoContact";
import {green} from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import ResetIcon from '@mui/icons-material/Refresh';
import {useTranslation} from "react-i18next";

export default function ContactsListBlock() {
    const contacts = useSelector(contactsSelector)
    const isLoad   = useSelector(contactsOnLoadSelector)
    const dispatch = useDispatch()
    const [openModalInfo,setOpenModalInfo] = React.useState(false);
    const [currentOpenUserContact,setCurrentOpenUserContact] = React.useState(null);
    const {  t } = useTranslation();
    const paramsGet = {
        next:1,
        prev:0,
        perPage:NBR_CONTACT_PER_PAGE
    }
    if(isLoad && contacts.items.length === 0){
        getContacts(paramsGet).then((data)=>{
            if(data){
                dispatch(setContactLoading(false))
                dispatch(setContactInfos(data))
            }
        }).catch((error) => {
            console.log(error)
        }).finally(()=>{
            dispatch(setContactLoading(false))
        })
    }
    const handleClickOpen = (user) => {
        setOpenModalInfo(true);
        setCurrentOpenUserContact(user)
    };

    const onResetListContact = () => dispatch(refactContactToDefaultList())

    const handleClose = () => {
        setOpenModalInfo(false);
    };
    return (
        <>
            <div className={"contact"}>
                <h2>Contacts</h2>
                <div className="divider"></div>
                <>
                    { contacts.items.length > 0 ?
                        <>
                            <ul>
                                {contacts.items.map((userContact, index) => <ItemContactBlock userContact={userContact} openMoreInfos={handleClickOpen}
                                                                                              key={`contact-${index}`}/>)}
                            </ul>
                            { !isLoad ?
                                <>
                                    { contacts.pagination.next !== '' &&
                                        <Link className="evzt-text-center load-more" to={"#"} onClick={(ev) => onClickLoadMoreContact(ev,paramsGet,contacts.pagination.next,dispatch)}>
                                            {t('loadMore')} ...
                                        </Link>
                                    }
                                    {
                                        contacts.items.length > NBR_CONTACT_PER_PAGE ?
                                            <div className="evzt-text-center">
                                                <IconButton sx={{ color: green[500] }} onClick={onResetListContact}>
                                                    <ResetIcon/>
                                                </IconButton>
                                            </div>     
                                            : ""
                                    }
                                </>
                                : ''}
                        </>
                        :
                        !isLoad ? <div className={"evzt-p15"}>(0)</div> :""
                    }
                </>
                { isLoad ? <LoadingItemsVertical count={NBR_CONTACT_PER_PAGE}/> : ""}
            </div>
            {contacts.items.length > 0 ?  <ModalInfoContact open={openModalInfo}
                                                            userContact={currentOpenUserContact}
                                                            handleClose={handleClose}
            />: ""}
        </>
    );
}