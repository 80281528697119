/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 15/02/2023
 * Time: 10:41
 */

import React, {Component} from 'react';
import Header from "../../Blocks/Header";
import ProfileBlock from "../../Blocks/ProfileBlock";
import ContactsListBlock from "../../Blocks/ContactsListBlock";
import ModalMageGuestEvent from "../../Blocks/Modal/ModalMageGuestEvent";
import axios from "axios";
import {API_EVENT_DETACH_USERS, API_EVENT_GUEST, NBR_CONTACT_PER_PAGE} from "../../Utils/Defines";
import ItemGuestInEvent from "../../Blocks/ItemGuestInEvent";
import LoadingItemsVertical from "../../Blocks/LoadingItemsVertical";
import ModalConfirmRemoveGuest from "../../Blocks/Modal/ModalConfirmRemoveGuest";
import {Alert} from "@mui/material";
import {displayUserFullName} from "../../Utils/Common";
import { BrowserView } from 'react-device-detect';
class ListGuest extends Component {
    constructor(props) {
        super(props);
        this.state={
            serverSuccessMessage : false,
            serverErrorMessage : false,
            isLoad : true,
            guests : [],
            currEvent  : {
                name : "",
                id   : this.props.match.params.id
            },
            userToDetach  : null,
            openModalForm : false,
            openModalRemove:false,
        }
    }
    componentDidMount=()=>{
      this.fetchListGuest()
    }

    fetchListGuest = () =>{
        this.setState({isLoad:true})
        axios.get(API_EVENT_GUEST.replaceAll("{id}",this.state.currEvent.id))
            .then((response) => {
                const {guests,event} = response.data.success
                if(event){
                    this.setState(prev => Object.assign(prev.currEvent,event))
                }
                if(guests){
                    this.setState({
                        guests : guests
                    })
                }
                this.setState({isLoad:false})
            })
            .catch((error) => {
                this.setState({isLoad:false})
                console.log(error);
                return null
            })
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        console.log(prevState);
    }

    toggleModalForm=(dataAdded)=>{
        this.setState({
            openModalForm : !this.state.openModalForm
        })
        //Last Guest
        if(dataAdded){
            this.fetchListGuest()
            this.setServerSuccessMsg("Guest "+displayUserFullName(dataAdded)+" added")
        }
    }
    onOpenModalRemove = (guest) => {
        this.setState({
            openModalRemove : true,
            userToDetach : guest
        })
    }
    onCloseModalRemove = () => {
        this.setState({
            openModalRemove : false,
            userToDetach : null
        })
    }

    onDetachGuest = () =>{
        const {userToDetach,currEvent} = this.state
        if(userToDetach && currEvent){
            axios.post(API_EVENT_DETACH_USERS,{
                id : currEvent.id,
                event_invitations : [{
                    id : userToDetach.invitation_id
                }]
            })
                .then((response) => {
                    const {event_invitations} = response.data.success
                    if(event_invitations){
                        this.setServerSuccessMsg("Guest "+displayUserFullName(userToDetach)+" removed")
                        this.fetchListGuest()
                        this.setState({
                            openModalRemove : false,
                            userToDetach : null
                        })
                    }
                })
                .catch((error) => {
                    const {message} = error.response.data.error
                    this.setServerErrorMsg("Error server during delete guest => "+message)
                    return null
                })
        }else{
            this.setServerErrorMsg("Error during delete guest")
        }
    }

    setServerErrorMsg = (message) =>{
        this.setState({
            serverErrorMessage : message
        })
    }

    setServerSuccessMsg = (message) =>{
        this.setState({
            serverSuccessMessage : message
        })
    }
    render() {
        const {
            guests,
            openModalForm,
            isLoad,
            openModalRemove,
            userToDetach,
            currEvent,
            serverSuccessMessage,
            serverErrorMessage
        } = this.state;
        console.log(guests)
        return (
            <>
                <Header/>
                <div className="evzt-container app-content">
                   <BrowserView>
                    <div className={"app-left-side"}>
                            <ProfileBlock/>
                            <ContactsListBlock/>
                        </div>
                   </BrowserView>
                    <div className="principal-content">
                        <div className="evzt-container">
                            <h1>Guest List</h1>
                            <div className="content-top evzt-text-right">
                                <button className='evzt-btn btn-blue' onClick={ ev => this.toggleModalForm()}>Add new</button>
                            </div>
                            <div className="guests evzt-bg-white">
                                {serverSuccessMessage && <Alert onClick={ () => this.setServerSuccessMsg(false)} severity={"success"}>{serverSuccessMessage}</Alert>}
                                {serverErrorMessage && <Alert onClick={ () => this.setServerErrorMsg(false)} severity={"error"}>{serverErrorMessage}</Alert>}
                                <table className="evzt-table">
                                    <thead>
                                    <tr>
                                        <th className="evzt-text-left">Guest number</th>
                                        <th className="evzt-text-left">Fullname</th>
                                        <th className="evzt-text-center">Status</th>
                                        <th className="action evzt-text-right">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {/* row */}
                                    {isLoad ?
                                        <>
                                            <tr><td colSpan={4}><LoadingItemsVertical count={NBR_CONTACT_PER_PAGE} /></td></tr>
                                        </>
                                        :
                                        guests.length > 0 ?
                                            guests.map( (item,pos) => <ItemGuestInEvent guest={item} pos={pos} onClickRemove={this.onOpenModalRemove}/>) :
                                            <tr><td colSpan={4}>No Guest on the time</td></tr>
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalMageGuestEvent event={currEvent} open={openModalForm}  handleClose={this.toggleModalForm} />
                <ModalConfirmRemoveGuest open={openModalRemove} user={userToDetach} handleYes={ this.onDetachGuest} handleNo={this.onCloseModalRemove}  />
            </>
        );
    }
}

ListGuest.propTypes = {};

export default ListGuest;