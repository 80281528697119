import { Link } from "react-router-dom";
import { Icon } from "@mui/material";
import React from "react";

import {
  INVT_ACCEPTED,
  INVT_REJECTED,
  SERVER_BASE_URL,
} from "../Utils/Defines";
/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 16/02/2023
 * Time: 14:41
 */

const ItemGuestInEvent = ({ pos, guest, onClickRemove }) => {
  /**
   *
   * @param state
   * @return {JSX.Element}
   */
  const stateView = (state) => {
    let res = (
      <>
        <span className="evzt-text-blue">
          <b>
            <Icon>hourglass_top</Icon>Pending for validation
          </b>
        </span>
      </>
    );
    switch (parseInt(state)) {
      default:
        res = (
          <>
            <span className="evzt-text-blue">
              <b>
                <Icon>hourglass_top</Icon>Pending for validation
              </b>
            </span>
          </>
        );
        break;
      case INVT_REJECTED:
        res = (
          <>
            <span className="evzt-text-danger">
              <b>
                <Icon>close</Icon>Rejected
              </b>
            </span>
          </>
        );
        break;
      case INVT_ACCEPTED:
        res = (
          <>
            <span className="evzt-text-success">
              <b>
                <Icon>checked</Icon>
                <span className="evzt-text-checked">Validated</span>
              </b>
            </span>
          </>
        );
        break;
    }
    return res;
  };
  return (
    <tr className="list-ct">
      <td className="list-id">
        <span>INVIT-{pos + 1}</span>
      </td>
      <td className="title">
        <div className="thumb">
          <img
            className="evzt-img-fluid"
            src={SERVER_BASE_URL + guest.avatar.substring(-1)}
            alt="Guest fullname"
          />
        </div>
        <div className="guest-list-info">
          <h5 className="guest-list-title">
            <Link to="#">{guest.first_name + " " + guest.last_name}</Link>
          </h5>
          <p className="guest-email evzt-text-default">{guest.email}</p>
        </div>
      </td>
      <td className="guest-state-value evzt-text-center">
        {stateView(guest.state)}
      </td>
      <td>
        <ul className="evzt-table-actions">
          {/*<li><a href="/" className="evzt-text-default"><Icon>visibility</Icon></a></li>*/}
          <li>
            <Link to="/" className="evzt-text-blue" title={"Resend Invitation"}>
              <Icon className="evzt-icon">refresh</Icon>
            </Link>
          </li>
          <li>
            <span
              title={"Uninvite"}
              onClick={(ev) => onClickRemove(guest)}
              className="evzt-text-danger"
            >
              {/* <Icon fontSize="30">deleteForever</Icon> */}
              <Icon className="evzt-icon">deleteForever</Icon>
            </span>
          </li>
        </ul>
      </td>
    </tr>
  );
};

export default ItemGuestInEvent;
