import ContactsListBlock from "../Blocks/ContactsListBlock";
import ProfileBlock from "../Blocks/ProfileBlock";
import EventsUser from "../Blocks/EventsUser";
import EventsGuestBlock from "../Blocks/EventsGuestBlock";
import RandomImagesEventBlock from "../Blocks/RandomImagesEventBlock";
import { BrowserView } from 'react-device-detect';

/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 14/03/2023
 * Time: 15:31
 */

export const USER_ACCESS = {
    "USER_GUEST" : {
        "blockMenuLeft" : [
            <ProfileBlock/>
        ],
        "blockContainers" : [
            <EventsGuestBlock />,
        ],
    },
    "USER_CLIENT" : {
        "blockMenuLeft" : [
            <BrowserView>
                <ProfileBlock/>
            </BrowserView>
            ,
            <BrowserView>
                <ContactsListBlock/>
            </BrowserView>
        ],
        "blockContainers" : [
            <BrowserView>
                <EventsGuestBlock />
            </BrowserView>,
            <BrowserView>
                <EventsUser />
            </BrowserView>,
            <RandomImagesEventBlock />
        ],
    }
}