import React from 'react'
import { createButton } from 'react-social-login-buttons'

// GOOGLE ICON //
function IconGoogle(){
   return(
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="30" height="30">
  <path fill="#FFC107" d="M43.61 20.08H42V20H24v8h11.3a12 12 0 1 1-3.34-12.96l5.66-5.66a20 20 0 1 0 6 10.7z"/>
  <path fill="#FF3D00" d="m6.3 14.7 6.58 4.81a12 12 0 0 1 19.08-4.47l5.66-5.66a19.99 19.99 0 0 0-31.31 5.3z"/>
  <path fill="#4CAF50" d="M24 44a19.9 19.9 0 0 0 13.4-5.2l-6.18-5.23a12 12 0 0 1-18.5-5.52L6.2 33.08A19.98 19.98 0 0 0 24 44z"/>
  <path fill="#1976D2" d="M43.61 20.08H42V20H24v8h11.3a12.04 12.04 0 0 1-4.08 5.57l6.19 5.24C36.97 39.2 44 34 44 24c0-1.34-.14-2.65-.39-3.92z"/>
</svg>

   )
}
  /// FACEBOOK ICON//
function IconFacebooK(){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 48 48">
        <linearGradient id="a" x1="9.99" x2="40.62" y1="9.99" y2="40.62" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#2aa4f4"/>
            <stop offset="1" stop-color="#007ad9"/>
        </linearGradient>
        <path fill="url(#a)" d="M24 4a20 20 0 1 0 0 40 20 20 0 0 0 0-40z"/>
        <path fill="#fff" d="M26.7 29.3h5.18l.82-5.26h-6v-2.87c0-2.19.72-4.12 2.76-4.12h3.29v-4.59c-.58-.08-1.8-.25-4.1-.25-4.82 0-7.64 2.54-7.64 8.34v3.5h-4.95v5.25h4.95v14.45c.98.15 1.97.25 2.99.25.92 0 1.82-.08 2.7-.2V29.3z"/>
        </svg>
    )
}

// LINKEDIN ICON
function IconLinkedin(){
    return(
<svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 48 48">
  <path fill="#0078d4" d="M24 4a20 20 0 1 0 0 40 20 20 0 0 0 0-40z"/>
  <path d="M30 35v-9a2 2 0 0 0-4 0v9h-6V18h6v1.03a6.5 6.5 0 0 1 10 5.47V35h-6zm-17 0V18h2.97A2.89 2.89 0 0 1 13 15c0-1.74 1.27-3 3.01-3 1.7 0 2.95 1.25 2.99 2.98A2.92 2.92 0 0 1 15.99 18H19v17h-6z" opacity=".05"/>
  <path d="M30.5 34.5V26a2.5 2.5 0 0 0-5 0v8.5h-5v-16h5v1.53a6 6 0 0 1 10 4.47v10h-5zm-17 0v-16h5v16h-5zm2.47-17A2.4 2.4 0 0 1 13.5 15c0-1.45 1.06-2.5 2.51-2.5 1.44 0 2.46 1.02 2.49 2.49a2.43 2.43 0 0 1-2.51 2.51h-.02z" opacity=".07"/>
  <path fill="#fff" d="M14 19h4v15h-4V19zm1.99-2h-.02c-1.2 0-1.97-.89-1.97-2 0-1.14.8-2 2.01-2 1.22 0 1.97.86 1.99 2 0 1.11-.77 2-2.01 2zM35 24.5a5.5 5.5 0 0 0-10-3.16V19h-4v15h4v-8a3 3 0 1 1 6 0v8h4v-9.5z"/>
</svg>

    )
}

// CREATION BUTTON GOOGLE
const signUpGoogle = {
    text :'',
    icon : IconGoogle,
    iconFormat: (name) => `fa fa-${name}`,
    style: { background: "transparent", width:'fit-content', border:'none','borderRadius':'50% 50%'},
    // activeStyle: { background: "#f0f0f0" },
}
export const GoogleSingUpButton = createButton(signUpGoogle);

// CREATION BUTTON FACEBOOK
const signUpFacebook = {
    text:'',
    icon: IconFacebooK,
    iconFormat: (name)=> `fa fa-${name}`,
    style : {background: "transparent", width:'fit-content', border:'none','borderRadius':'50% 50%'},
    // activeStyle:{background:'#f0f0f0'},
}
export const FacebookSingUpButton = createButton(signUpFacebook);

// CREATION BUTTON LINKEDIN //
const SingUpLinkedin = {
    text : '',
    icon: IconLinkedin,
    iconFormat: (name)=>`fa fa-${name}`,
    style : {ckground: "transparent", width:'fit-content', border:'none','borderRadius':'50% 50%'},
    // activeStyle: {bagckround:'#f0f0f0'},
}
export const LinkedinSIngUpButton = createButton(SingUpLinkedin);