/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 30/06/2021
 * Time: 20:00
 */

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {withCookies} from "react-cookie";
import {isExpireToken} from "./Utils/Common";
// handle the private routes
function PrivateRoute({ component: Component, ...rest }) {
    const {cookies}= rest;
    const token = cookies.get("ac",{path:"/"});
     //console.log(token,rest)
/*    if (token){
        console.log("test")
        axios.defaults.headers.common['Authorization'] = "Bearer "+token.access_token;
    }*/
    return (
        <Route
            {...rest}
            render={(props) => !isExpireToken(token)? <Component {...props} /> : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />}
        />
    )
}

export default withCookies(PrivateRoute);