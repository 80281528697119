import Header from "../../Blocks/Header";
import ProfileBlock from "../../Blocks/ProfileBlock";
import ContactsListBlock from "../../Blocks/ContactsListBlock";
import React, {useEffect, useLayoutEffect, useRef, useState,useCallback, ReactNode} from "react";
import {getEventById, getEventsImages} from "../../Utils/Common";
import {API_PICTURE_DELETE, SERVER_BASE_URL} from "../../Utils/Defines";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";
import {Gallery as GalleryLibrary} from "react-grid-gallery";
import Counter from "yet-another-react-lightbox/plugins/counter";
import {useTranslation} from "react-i18next";
import {Link, useParams} from "react-router-dom";
import LoadingItemsHorizontal from "../../Blocks/LoadingItemsHorizontal";
import RenderSlideCustom from "../../Blocks/LightBoxCustom/RenderSlideCustom";
import Download from "yet-another-react-lightbox/plugins/download";
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import axios from "axios";
import PrivateFooter from "../../Blocks/PrivateFooter";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";
import ModalUploadImageEvent from "../../Blocks/Modal/ModalUploadImageEvent";
import { Icon} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { BrowserView,MobileView } from 'react-device-detect';
import PrivateMobileFooter from "../../Blocks/Mobiles/PrivateMobileFooter";
import { Event,ImageItem, Pagination } from "../../Utils/CommonTypes";
import SocialShare from "./SocialShare";
import { MenuSocialShare } from "../../Blocks/MenuSocialShare";
type RefPrincipal = {
    offsetHeight : number,
    offsetWidth  : number
}
/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 17/03/2023
 * Time: 19:42
 */

type IndexState = Array<any>
type idEventParams = {
    idEvent?: string;
  };
const GuestImageList = () => {
    const theme = useTheme();
    const {idEvent} = useParams<idEventParams>();
    const [event,setEvent]  = useState<Event|null>(null);
    const [eventLoading,setEventLoading] = useState(false)
    const [images,setImages] = useState<Array<ImageItem>>([])
    const [index, setIndex]:IndexState = useState(-1);
    const [pagination,setPagination] = useState<Pagination>({
        next : 1,
        prev : 0,
        perPage:0,
        currentItems:0,
        count:0,
    })
    const [loadImages,setLoadImages] = useState(false);
    const refPrincipal = useRef<HTMLDivElement>(null);
    const [open, setOpen] = React.useState(false);
    const currentImage:ImageItem = images[index];
    const [hasSelected, setHasSelected] = useState(false);
    const [isFirstLoading, setIsFirstLoading] = useState<boolean>(true);
    const [countSelected, setCountSelected] = useState(0);
    const [isShareOpen, setIsShareOpen]  = useState<boolean>(false);
    const [urlsSelected,setUrlsSelected] = useState<Array<string>>([]);
    


    /** Fuctions */
    const {  t } = useTranslation();
    const handleClick = (index:number, item:any) => {
        setIndex(index);
        setOpen(true)
    }
    const fetchImage = () => {
        const paramsGet = {
            ...pagination,
            filters : []
        }
        setLoadImages(true)
        getEventsImages(idEvent,paramsGet).then(
            data => {
                if(data){
                    const realData = data.items.map((item:ImageItem,p:number) => {
                        const tags:Array<string> = [];
                        const regex = /https:\/\//g;
                        const found = item.src.match(regex)
                        if(!found){
                            item.src = SERVER_BASE_URL+item.src;
                        }
                        item.downloadUrl = `${item.origin}?download`
                        item.downloadFilename = `${event?.name}-${p+1}`
                        //item.download=`${item.origin}?download`
                        item.title = '';
                        if(typeof item.tags !=="undefined")
                            item.tags.forEach((tag) => tags.push(tag.title))
                        item.description = tags.join(", ")
                        item.tags = []
                        return item;
                    })
                    setImages((prevImages) => prevImages ? [...prevImages,...realData] : realData )
                    setPagination((prevPagination) => {
                        prevPagination.prev     = data.pagination.prev===""?0:data.pagination.prev
                        prevPagination.next     = data.pagination.next===""?0:data.pagination.next
                        prevPagination.count    = data.count
                        prevPagination.currentItems += data.items.length
                        return prevPagination
                    })
                }else{
                    setImages([])
                }
                setLoadImages(false)
            }
        ).finally( () => setLoadImages(false))
    }
    const  delay=(ms:number) =>{
        return new Promise(resolve => {
          setTimeout(resolve, ms);
        });
      }
      
    if(!event && !eventLoading){
        setEventLoading(true);
        getEventById(idEvent).then(res =>  {
            if(res.success){
                setEvent(res.success.event)
            }
        }).catch(() => null).finally(
            () => setEventLoading(false)
        )
    }
    if(!loadImages && isFirstLoading && event){
        fetchImage();
        setIsFirstLoading(false)
    }

    

    useLayoutEffect(() => {
        //setHeightBlockPrincipal(refPrincipal.current.offsetHeight);
        setPagination((prevPagination) =>{
            if(refPrincipal && refPrincipal.current){
                const sizeBaseHeight =  refPrincipal.current.offsetHeight > 0 ? refPrincipal.current.offsetHeight : 180
                const sizeBaseWidth =  refPrincipal.current.offsetWidth > 0 ? refPrincipal.current.offsetWidth : 210
                prevPagination.perPage = (Math.floor(4 * (sizeBaseHeight / 180)) * Math.floor(sizeBaseWidth / 210))
            }
            return prevPagination;
        })
    }, []);

    /**
     *
     * @returns {number|number}
     */
    const getCurrentNbLoader = () => {
       return  pagination.count === 0 || (pagination.count - pagination.currentItems > pagination.perPage)  ?  pagination.perPage : pagination.count- pagination.currentItems
    }
    
    if(currentImage){
        currentImage.name = t("picture")
    }
    const handleSelectedImages = useCallback(() => {
        if(images){
            let selected = false;
            images.forEach(objImage => {
                if(objImage.isSelected){
                    selected = true;
                    return;
                }
            });
            setHasSelected(selected);
            setCountSelected(images.filter((img) => true === img.isSelected).length);
        }
    },[images])

    // La fonction handleSelectedImages sera appelée chaque fois que images sont chargées ou mises à jour
    useEffect(() => {
        handleSelectedImages();
    }, [images,handleSelectedImages]);

    const handleSelect = (index:number) => {
        const urls:Array<string> = []
        const updatedImageList = images.map((image, i) =>{
            return i === index ? { ...image, isSelected: !image.isSelected } : image
        });
        updatedImageList.map((image)=>{
            if(image.isSelected){
                urls.push(image.origin)
            }
        })
        setImages(updatedImageList);
        setUrlsSelected(urls);
    }

 

    const haveAnImageSelected = () => {
        return images.filter((img) => true === img.isSelected ).length > 0;
    }

    const handleSelectAll = () => {
        const nextImages = images.map((image) =>({
            ...image, isSelected: !hasSelected,
        }));
        setImages(nextImages);
    }

    return (
        <>
            <Header/>
            <div className="evzt-container app-content">
                
               <BrowserView>
                    <div className={"app-left-side"}>
                        <ProfileBlock/>
                        <ContactsListBlock/>
                    </div>
               </BrowserView>
                <div className="principal-content" ref={refPrincipal}>
                    <div className={"event-block"}>
                        <div className="evzt-row">
                            <div className="evzt-lg-8">
                                {event && <h2>{t('picturesFor')} <strong>#{event.name}</strong></h2>}
                                {pagination.count > 0 && <p className={"evzt-title-desc"}>{t('pagination.gallery.description',{count:pagination.count,items:images.length})}</p>}
                            </div>
                            {/* @todo : Access from owner  */}
                            {event && event.folders && 
                             <div className="import-Photos-gallery">
                                    <Link to={"#"} className="evzt-btn btn-white" onClick={(ev) => {
                                        ev.preventDefault();
                                    }}>{t("form.upload.btn")}</Link>
                                </div>
                            }
                        </div>
                        <div className="gallery-actiongroup">
                            <div className="actiongroup-gallery-button">
                                <button className="evzt-btn btn-icon" onClick={handleSelectAll} style={{cursor: 'pointer'}}>{hasSelected ? <Icon color="primary">check_box</Icon> : <Icon color="primary">check_box_outline_blank_icon</Icon> }</button>
                                {hasSelected && 
                                    <>
                                        <button className="evzt-btn btn-icon" 
                                         onMouseEnter={() => setIsShareOpen(true)}
                                         >
                                            <Icon color="secondary">share</Icon>
                                        </button>  
                                        {isShareOpen && <div style={{
                                             "position": "relative",
                                             "zIndex": 2,
                                             "minWidth" : "210px",
                                             "right"    : "30px"
                                        }} onMouseLeave={() => setIsShareOpen(false)}>
                                            <MenuSocialShare 
                                                style={{
                                                    "position": "absolute",
                                                    "zIndex": 2
                                                }} urls={urlsSelected} />
                                        </div>}
                                    </>
                                }
                            </div>
                            <div className="BackToMyspace">
                                <Link to={"/my-space"} title='back to Myspace'>
                                    <button className="evzt-btn btn-white"> <Icon>arrow_back</Icon>  {t('BackToMyspace')}</button>
                                </Link>
                            </div>
                        </div>
                        <div className="random-images-gallery">
                            {images.length > 0 ? <GalleryLibrary
                                onClick={handleClick}
                                enableImageSelection={true}
                                onSelect={handleSelect}
                                images={images} />
                                :
                                <>
                                    {/* @todo : Custom this element */}
                                    {!loadImages && <p>Empty List</p>}
                                </>
                            }
                                {!!currentImage && (
                                    <Lightbox
                                        open={open}
                                        close={() => setOpen(false)}
                                        slides={images}
                                        index={index}
                                        plugins={[Download,Counter,Captions]}
                                        toolbar={{
                                            buttons: [
                                                currentImage.id > 0 && (
                                                    <IconButton key={"rm-picture"}  aria-label="delete" className="yarl__button" size="large">
                                                        <DeleteIcon  fontSize="inherit" color="error"/>
                                                    </IconButton>
                                                ),
                                                <IconButton
                                                    key={"share-facebook"}
                                                    aria-label="share-facebook"
                                                    className="yarl__button"
                                                    size="large"
                                                    >
                                                        <SocialShare
                                                        provider="facebook"
                                                        url={currentImage.origin}
                                                        />
                                                </IconButton>,
                                                <IconButton
                                                        key={"share-messenger"}
                                                        aria-label="share-messenger"
                                                        className="yarl__button"
                                                        size="large"
                                                    >
                                                        <SocialShare
                                                        provider="messenger"
                                                        url={currentImage.origin}
                                                        />
                                                </IconButton>,
                                                <IconButton
                                                        key={"share-whatsapp"}
                                                        aria-label="share-whatsapp"
                                                        className="yarl__button"
                                                        size="large"
                                                    >
                                                    <SocialShare
                                                    provider="whatsapp"
                                                    url={currentImage.origin}
                                                    />
                                                </IconButton>,
                                                "download",
                                                "close",
                                            ],
                                        }}
                                        render={{
                                            slide:({slide}) => {
                                                const decorateSlide:any={...slide}
                                                return <RenderSlideCustom slide={{
                                                    type:"evzt",
                                                    origin: decorateSlide.origin,
                                                    src: slide?.src ? slide?.src : "",
                                                    title: decorateSlide.title,
                                                    subtitle: "",
                                                    tags:decorateSlide.tags,
                                                    height:slide?.height ? slide?.height : 100,
                                                    width:slide?.width ? slide?.width : 100,
                                                }} />
                                            }
                                        }}
                                        carousel={{
                                            padding: 0,
                                            spacing: 0
                                            }}
                                        animation={
                                            { fade: 250, swipe: 100, easing: { fade: "ease", swipe: "ease-out", navigation: "ease-in-out" } }
                                        }
                                        counter={{ container: { style: { top: 0, bottom: "unset" } } }}
                                    />
                                )}
                        </div>
                        {loadImages && <LoadingItemsHorizontal count={getCurrentNbLoader()}/>}
                        <div className="loader-data-block">
                            {images.length > pagination.perPage && pagination.prev > 0 &&
                                <Link className="evzt-btn-load less" to={"#"} onClick={(ev) => {
                                    const maxList = (images.length-pagination.perPage)
                                    setPagination((prevPagination) => {
                                        console.log(prevPagination)
                                        prevPagination.next =  prevPagination.prev+1;
                                        prevPagination.prev =  prevPagination.prev-1 > 0 ? prevPagination.prev-1 : 0;
                                        prevPagination.currentItems = maxList
                                    
                                        return prevPagination;
                                    })
                                    setImages((prevImages) => prevImages.slice(0,maxList))
                                }}>
                                    {t('loadLess')} <Icon>remove_circle</Icon> </Link>
                            }
                            {pagination.count > pagination.perPage && pagination.next >0 &&
                                <Link className="evzt-btn-load more" to={"#"} onClick={(ev) => fetchImage()}>
                                    {t('loadMore')} <Icon>add_circle</Icon></Link>
                            }
                        </div>
                    </div>
                </div>
                <BrowserView>
                    <PrivateFooter />
                </BrowserView>
                <MobileView>
                    <PrivateMobileFooter />
                </MobileView>
            </div>
        </>
    );
}

export default GuestImageList