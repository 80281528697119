import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const LinkToCreateNewAccount = (prop) => {
    const {t} = useTranslation()
    return (
        <>
            <p>{t('ifDontHaveAccount')},<Link to={'/sign-up'}>{t('createAccount')}</Link>. {t('itSQuickEasy')}</p>
        </>
    )
}