import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {BootstrapDialog, BootstrapDialogTitle} from "./ModalCommonHead";
import FormGuest from "../Form/FormGuest";

/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 29/01/2023
 * Time: 19:05
 */
/**
 *
 * @param open
 * @param handleClose
 * @param {name:""} event
 * @return {JSX.Element}
 * @constructor
 */
const ModalMageGuestEvent = ({open,event,handleClose}) => {
    return (
        <div>
            <BootstrapDialog
                aria-labelledby="customized-dialog-title-manage-guest"
                open={open}
            >
                <BootstrapDialogTitle id="customized-dialog-title-manage-guest" onClose={ ev => handleClose(null)}>
                   Add Guest {event ? event.name : ""}
                </BootstrapDialogTitle>
                <DialogContent>
                    <Typography gutterBottom>
                        <FormGuest  event={event} onSuccessAdd={handleClose}/>
                    </Typography>
                </DialogContent>
            </BootstrapDialog>
        </div>
    );
}

export default ModalMageGuestEvent