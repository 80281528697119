/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 08/04/2023
 * Time: 12:01
 */

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resourcesToBackend from 'i18next-resources-to-backend';
import { getUserLang } from "./Common";

i18n
  .use(resourcesToBackend((language: any, namespace: any) => {
    console.log(language,namespace);
    return import(`../locales/${language}/${namespace}.json`)
  }))
  .use(initReactI18next)
  .on('failedLoading', (lng, ns, msg) => console.error(msg));
  i18n.on('loaded', function(loaded) {
    console.log(loaded)
  })

 i18n.init({
    lng: getUserLang(),
    fallbackLng: getUserLang(),
    interpolation: {
      escapeValue: false,
    },
  })
// i18n.loadLanguages(['en', 'fr'])
//i18n.changeLanguage(getUserLang());

export default i18n;
