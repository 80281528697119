/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 15/04/2023
 * Time: 11:49
 */
import {useTranslation} from "react-i18next";
import { Link } from "react-router-dom";

const PrivateFooter = () => {
    const d = new Date()
    const {t} = useTranslation()
   return (
       <div id={"privateFooter"}>
        <div className={"evzt-block-footer"}>
            <div className={"evzt-footer-container links"}>
                <div className="wrapper link-list">
                    <div><Link to={"/about"} color="inherit">{t("about")}</Link></div>
                    <div><Link to={"/terms/services"}>{t("termsOfService")}</Link></div>
                    <div><Link to={"/privacy/policy"}>{t("privacy")}</Link></div>
                </div>

            </div>
        </div>
         <div className={"evzt-copy-right"}>
             <div className={"evzt-copy-right-content"}>
                 <strong className={""}>
                    {d.getFullYear()}
                    &nbsp;<span className={"evzt-text-primary"}>&copy;</span> Lead Code Group</strong>
                 </div>
         </div>
       </div>
   )
}

export default PrivateFooter;