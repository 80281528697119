import ErrorMessageEvztField from '../Blocks/ErrorMessageEvztField';
function InputPassword (
    {inputName, 
    register, 
    validationRules, 
    errors, 
    inputClassList, 
    placeHoldIt, 
    label='',
    isVisiblePassword,
    setIsVisiblePassword
    }) {
    return <>
        {label !== '' && <label htmlFor={inputName}>{label}</label>}
        <div className={"input-password-block"}>
        <input {...register(inputName, validationRules)} type={`${isVisiblePassword ? "text" : "password"}`}
            className={(inputClassList && typeof inputClassList === "object")
            ? inputClassList.map( inputClassName => inputClassName) : ""} placeholder={placeHoldIt}/>
            <span onClick={() => setIsVisiblePassword(!isVisiblePassword)}
                className={`icon-password ${isVisiblePassword ? "icon-eye" : "icon-eye-trash"}`}>
            </span>
        </div>
        <ErrorMessageEvztField errors={errors} fieldName={inputName}/>
    </>
}

export default InputPassword;
