import React from "react";
import { useTranslation } from "react-i18next";
import {
  FacebookShare,
  FacebookMessengerShare,
  WhatsappShare,
} from "react-share-lite";


type Props = { provider: string; url: string };
const SocialShare = ({ provider, url }: Props) => {
  const { t } = useTranslation(['buttons']);
  const fbTitle:string = t('share.FB',{ ns: 'buttons' });
  console.log(fbTitle)
  return (
    <React.Fragment>
      {provider === "facebook" && (
        <FacebookShare
          url={url}
          title={fbTitle}
          bgColor="#e6ebe7"
          color="#0866ff"
          size={36}
          round={true}
          quote=""
        />
      )}
      {provider === "messenger" && (
        <FacebookMessengerShare
          color="#0866ff"
          url={url}
          title={fbTitle}
          bgColor="#e6ebe7"
          size={36}
          appId={"facebook-application-ID"}
          round={true}
        />
      )}
      {provider === "whatsapp" && (
        <WhatsappShare url={url} size={36}  color="#00a884" bgColor="#e6ebe7" round={true}/>
      )}
    </React.Fragment>
  );
};

export default SocialShare;
/**
 * Share Providers : 
 *  Facebook Share
    Twitter Share
    Linkedin Share
    Whatsapp Share
    Telegram Share
    FacebookMessenger Share
    Email Share
    VK Share
    Pinterest Share
    Reddit Share
    Line Share
    Tumblr Share
    Viber Share
    Weibo Share
    Mailru Share
    LiveJournal Share
    Workplace Share
    Pocket Share
    Instapaper Share
    Hatena Share
    Gab Share
 */
