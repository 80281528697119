import PublicFooter from "../../Blocks/PublicFooter"
import PublicHeader from "../../Blocks/PublicHeader"
import { Trans } from 'react-i18next';
import { useState } from "react";
import { onUnSubscribeNewsLetter } from "../../Utils/Common";

const NewsLetterUnsubscribe=()=>{
    const MSG_TYPE_NOTFOUND_EMAIL   = 0
    const MSG_TYPE_ALREADY_DISABLED = 1
    const MSG_TYPE_SUCCESS          = 2
    const [currentMessage, setCurrentMessage] = useState(-1);
    const queryParams = new URLSearchParams(window.location.search);
    const token       = String(queryParams.get('token')).replaceAll(" ","+");
    console.log(token)
    const onSubmittedFeedBack = (data:{err:any,success:string}) => {
        if(typeof data.err !=="undefined"){
            if(data.err.errors === "EMAIL_NOT_PRESENT"){
                setCurrentMessage(MSG_TYPE_NOTFOUND_EMAIL)
            }
            console.log(data.err.errors)
        }else if(typeof data.success !=="undefined"){
            setCurrentMessage(MSG_TYPE_SUCCESS)
        }
   }
    onUnSubscribeNewsLetter(token,onSubmittedFeedBack).then(
        (value) => console.log(value)
    ).catch((err) => console.log(err.response))
    return (
        <>
            <PublicHeader />
            <section id="main-unsubscribe" style={{
                "minHeight":"500px",
            }}>

                <div style={{
                    "width":"80%",
                    "margin": "10% auto"
                }}> 
                    {currentMessage > -1
                        &&
                        <>
                            {currentMessage === MSG_TYPE_NOTFOUND_EMAIL &&
                                 <div className="evzt-alert">
                                    <Trans i18nKey="newsletter.unsubscribe.message.notfound">
                                        We are really sad to hear that you are already leaving us, don't worry if you change your mind, our newsletter subscription is still open for you. Thank you for your presence with us during this time.
                                    </Trans>
                                </div>
                            }    
                            {
                                currentMessage === MSG_TYPE_ALREADY_DISABLED && 
                                <Trans i18nKey="newsletter.unsubscribe.message.alreadydisabled">
                                    We are really sad to hear that you are already leaving us, don't worry if you change your mind, our newsletter subscription is still open for you. Thank you for your presence with us during this time.
                                </Trans>
                            }
                            {
                                currentMessage === MSG_TYPE_SUCCESS && 
                                <Trans i18nKey="newsletter.unsubscribe.message.success">
                                    We are really sad to hear that you are already leaving us, don't worry if you change your mind, our newsletter subscription is still open for you. Thank you for your presence with us during this time.
                                </Trans>
                            }
                        </>
                    }   
                </div>
            </section>
            <PublicFooter />
        </>
    )
}

export default NewsLetterUnsubscribe
