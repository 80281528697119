/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 15/04/2023
 * Time: 17:11
 */
import PublicFooter from "../Blocks/PublicFooter";
import PublicHeader from "../Blocks/PublicHeader";
import image1 from "../assets/image/beautiful-bride-with-her-husband-park.jpg";
import image2 from "../assets/image/elderly-people-celebrating-their-birthday.jpg";
import image3 from "../assets/image/people-having-fun-block-party.jpg";
import { useTranslation } from "react-i18next";

const About = () => {
    const {t} = useTranslation();
    return (
        <>
            <PublicHeader />
            <section id={"privacyBlock"}>
                <div className="about-us evzt-text-center content-top">
                    <h1 className='abt-us-title'>{t("AboutUs")}</h1>
                </div>
                <div className='about-plan-container'>
                    <div className='abt-plan-box' id='abtPlanText'>
                        <h2 className='abt-plan-title'>Lorem Ipsum dolorem sapiente.</h2>
                        <p>Lorem Ipsum dolorem sapiente dolor amet.Lorem Ipsum dolorem sapiente dolor amet.Lorem Ipsum dolorem sapiente dolor amet.Lorem Ipsum dolorem sapiente dolor amet.</p>
                        <p>Lorem Ipsum dolorem sapiente dolor amet.Lorem Ipsum dolorem sapiente dolor amet.Lorem Ipsum dolorem sapiente dolor amet.Lorem Ipsum dolorem sapiente dolor amet.Lorem Ipsum dolorem sapiente dolor amet.Lorem Ipsum dolorem sapiente dolor amet.</p>
                        <p>Lorem Ipsum dolorem sapiente dolor amet.Lorem Ipsum dolorem sapiente dolor amet.</p>
                    </div>
                    <div className='abt-plan-box evzt-po-relative' id='abtPlanImages'>
                        <div className="abt-min-width">
                            <div className="abt-images-slide"><img className="abt-img" src={image1} alt="" /></div>
                        </div>
                        <div className="abt-min-width">
                            <div className="abt-images-slide"><img className="abt-img" src={image2} alt="" /></div>
                        </div>
                        <div className="abt-min-width">
                            <div className="abt-images-slide"><img className="abt-img" src={image3} alt="" /></div>
                        </div>
                        <div className="abt-cadre"></div>
                    </div>
                </div>
                <div className='about-user-reviews-box evzt-text-center'>
                    <h2 className='abt-reviews-title'>{t("UsersReviews")}</h2>
                    <div className='abt-user-reviews'>
                        <div className="abt-min-width">
                            <div className='abt-reviews-content'>
                                <div className='abt-user-review-profile'> <img className="abt-img" src={image1} alt="" /></div>
                                <h3>Paul Kowalsy</h3>
                                <p>"Lorem Ipsum dolorem sapiente dolor amet. Lorem Ipsum dolorem sapiente dolor amet."</p>
                            </div>
                        </div>
                        <div className="abt-min-width">
                            <div className='abt-reviews-content'>
                                <div className='abt-user-review-profile'> <img className="abt-img" src={image2} alt="" /></div>
                                <h3>Emilly Williams</h3>
                                <p>"Lorem Ipsum dolorem sapiente dolor amet. Lorem Ipsum dolorem sapiente."</p>
                            </div>
                        </div>
                        <div className="abt-min-width">
                            <div className='abt-reviews-content'>
                                <div className='abt-user-review-profile'> <img className="abt-img" src={image3} alt="" /></div>
                                <h3>John Doe</h3>
                                <p>"Lorem Ipsum dolorem sapiente dolor amet. Lorem Ipsum dolorem."</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='about-articles evzt-text-center'>
                    <h2 className='abt-articles-title'>{t("ArticlesfromtheBlog")}</h2>
                    <div className='abt-articles-images'>
                        <div className="abt-articles">
                            <div className="evzt-po-relative"><img className="abt-img" src={image1} alt="" />
                                <div className="abt-cadre"></div>
                            </div>
                            <h5 className="articles-date">10/04/24</h5>
                            <p>Lorem Ipsum dolorem sapiente dolor amet.</p>
                        </div>
                        <div className="abt-articles">
                            <div className="evzt-po-relative"><img className="abt-img" src={image2} alt="" />
                                <div className="abt-cadre"></div>
                            </div>
                            <h5 className="articles-date">14/04/24</h5>
                            <p>Lorem Ipsum dolorem sapiente dolor amet.</p>
                        </div>
                        <div className="abt-articles">
                            <div className="evzt-po-relative"><img className="abt-img" src={image3} alt="" />
                                <div className="abt-cadre"></div>
                            </div>
                            <h5 className="articles-date">22/04/24</h5>
                            <p>Lorem Ipsum dolorem sapiente dolor amet.</p>
                        </div>
                    </div>
                    <button type="submit" className="abt-btn evzt-btn" id="abtMoreBtn">{t("MorePosts")}</button>
                </div>
                <div className="about-contact evzt-text-center">
                    <h2 className="abt-contact-title">Lorem ipsum dolorem</h2>
                    <button type="submit" className="abt-btn evzt-btn" id="abtSendBtn">{t("SendRequest")}</button>
                </div>
            </section>
            <PublicFooter />
        </>
    )
}
export default About