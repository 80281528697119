import {createSlice} from "@reduxjs/toolkit";
import {getEventsUserAttendedInSession} from "../Utils/Common";
import {NBR_EVENT_PER_PAGE} from "../Utils/Defines";

/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 17/03/2023
 * Time: 09:41
 */

const EventsUserAttendedReducer = createSlice({
    name: 'eventsUserAttended',
    initialState: {
        ...getEventsUserAttendedInSession(),
        err :  null,
        formSaved:false
    },
    reducers: {
        putToList: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            console.log(typeof action.payload.items )
            if(typeof action.payload.items !=="undefined"){
                state.items = [...state.items,...action.payload.items]
                state.pagination = action.payload.pagination
                if(state.items.length > state.count)
                    state.count = state.items.length
            }else{
                state.items.push(action.payload);
                state.count += 1;
            }

            sessionStorage.setItem('eventsUserAttended', JSON.stringify({
                items : state.items,
                count : state.count,
                pagination : state.pagination,
                filters : state.filters,

            }));

            state.err =  null
        },

        setEventInfos : (state,action) => {
            state.items = action.payload.items
            state.count = action.payload.count
            state.pagination = action.payload.pagination
            state.formSaved = false;
            sessionStorage.setItem('eventsUserAttended', JSON.stringify({
                items : state.items,
                count : state.count,
                pagination : state.pagination,
                filters : state.filters
            }));
        },
        setMessageErr:(state, action) => {
            state.err = action.payload.err
        },
        cleanMessageErr:(state, action) => {
            state.err =  null
        },
        setEventGuestLoading : (state,action) => {
            state.onLoad = action.payload
        },
        setFormEventSaved : (state,action) => {
            state.formSaved = action.payload
        },
        removeEventInList:(state,action) => {
            state.items = state.items.filter((d,k) => d.id !== action.payload)
            state.count = state.items.length
            sessionStorage.setItem('eventsUserAttended', JSON.stringify({
                items : state.items,
                count :  state.count,
                pagination : state.pagination,
                filters : state.filters
            }));
        },
        changeFilterEvents:(state,action) => {
            state.items = []
            state.count = 0;
            state.onLoad = true;
            state.filters = action.payload
            sessionStorage.setItem('eventsUserAttended', JSON.stringify({
                items : state.items,
                count :  state.count,
                pagination : state.pagination,
                filters : action.payload
            }));
        },
        refreshEvent:(state,action) => {
            const k = state.items.findIndex((d,k) => d.id === action.payload.id)
            state.items[k] = action.payload
            sessionStorage.setItem('eventsUserAttended', JSON.stringify({
                items : state.items,
                count :  state.count,
                pagination : state.pagination,
                filters : state.filters
            }));
        },
        resetEvents : (state,action) => {
            state.items = []
            state.count = 0;
            state.onLoad = true;
        },
        refactToDefaultList : (state,action) =>{
            state.items = state.items.slice(0,NBR_EVENT_PER_PAGE);
            state.pagination.next = state.count > NBR_EVENT_PER_PAGE ? 2 : 1;
            sessionStorage.setItem('eventsUserAttended', JSON.stringify({
                items : state.items,
                count :  state.count,
                pagination : state.pagination,
                filters : state.filters
            }));
        }
    }
})

export const {
    putToList,setEventInfos,
    setEventGuestLoading,setMessageErr,
    cleanMessageErr,setFormEventSaved,
    removeEventInList,
    refreshEvent,
    changeFilterEvents,
    resetEvents,
    refactToDefaultList
} = EventsUserAttendedReducer.actions
export default EventsUserAttendedReducer.reducer