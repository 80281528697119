/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 28/01/2023
 * Time: 22:04
 */
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import {useTranslation,Trans} from "react-i18next";

/**
 *
 * @param open
 * @param objectToRemove
 * @param handleYes
 * @param handleNo
 * @returns {JSX.Element}
 * @constructor
 */
export default function ModalConfirmRemoveEvent({open,objectToRemove,handleYes,handleNo}) {
    const {  t } = useTranslation();
    console.log(objectToRemove)
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleNo}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{
                    "z-index":9999
                }}
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Trans i18nKey="confirmations.dialogue.title" values={{ subject: objectToRemove.name,count:objectToRemove?.count }}>
                            <strong>{objectToRemove.name}</strong>
                        </Trans>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleNo}
                        variant={"contained"}
                        color={"info"}
                    >{t('confirmations.dialogue.no')}</Button>
                    <Button
                        onClick={ ev => handleYes(objectToRemove)}
                        autoFocus
                        variant={"contained"}
                        color={"error"} >
                        {t('confirmations.dialogue.yes')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
