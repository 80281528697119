import {Alert, Button} from "@mui/material";
import React, { useState } from "react";
import {useForm} from "react-hook-form";
import InputPassword from "../../Utils/InputPassword";
import {onSubmitFormEditProfile} from "../../EventHandler/OnSubmitEvent";
import {useDispatch} from "react-redux";
import SpinerLoading from "../SpinerLoading";
import ErrorMessageEvztField from "../ErrorMessageEvztField";
import {useTranslation} from "react-i18next";
import {LANGUAGES} from "../../Utils/Defines";
import {setUserLang} from "../../Utils/Common";
import moment from "moment";

const FormProfileBlock = ({user}) => {
   const defaultD = {...user};
    defaultD.birth = user.birth !== "" ? moment(user.birth).format("YYYY-DD-MM") : "";
    const {register, formState:{errors},handleSubmit,setError} = useForm({defaultValues: defaultD});
    const [isVisiblePassword, setIsVisiblePassword] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [messageSuccess, setMessageSuccess] = useState("");
    const setServerErrors = (serverErrors) => {
        const mapMessages = {
            FIELD_CAN_NOT_BE_EMPTY : "This is required information"
        }
        for (const field in serverErrors) {
            setError(field, { type: 'custom', message: mapMessages["FIELD_CAN_NOT_BE_EMPTY"] ?? 'Required field' });
        }
    }
    const dispatch = useDispatch()
    const {t,i18n}      = useTranslation()
    const onSubmit = (d) => {
        setIsSubmit(true)
        onSubmitFormEditProfile(d, dispatch, setIsSubmit,setServerErrors).then(r  => {
            if(typeof r.success !== "undefined"){
                const local = r.success.local
                if(i18n.language !== local){
                    i18n.changeLanguage(local).then(r => setUserLang(local));
                }
                setMessageSuccess(t("formMsgSuccessUpdProfile"))
            }
            setIsSubmit(false)
        }).catch(response => console.log(response));
    };
    const maxDate = new Date()
    maxDate.setFullYear(maxDate.getFullYear()-10)
    return (
        <>
            <form className="profile-form" onSubmit={handleSubmit(onSubmit)}>
                {messageSuccess && (
                    <Alert severity="success">
                        {messageSuccess}
                    </Alert>
                )}
                <p className="evzt-form-title">{t("aboutYou")}</p>
                <div className="evzt-form-row">
                    <div className="evzt-form-group">
                        <label htmlFor="firstname">{t('formUserFirstname')}</label>
                        <input id="firstname" type='text' className="evzt-input-control" {...register("first_name",{required:"This is required information"})}/>
                    </div>
                    <div className="evzt-form-group">
                        <label htmlFor="lastname">{t('formUserLastname')}</label>
                        <input id="lastname" type='text' className="evzt-input-control" {...register("last_name",{required:"This is required information"})}/>
                    </div>
                    <div className="evzt-form-group">
                        <label htmlFor="username">{t('formUserUsername')}</label>
                        <input id="username" type='text' className="evzt-input-control" readOnly={true} {...register("username",{required:"This is required information"})}/>
                    </div>
                </div>
                {/* end row */}
                <div className="evzt-form-row">
                    <div className="evzt-form-group">
                        <label htmlFor="email">{t('formUserEmail')}</label>
                        <input id="email" type='text' className="evzt-input-control" {...register("email",{required:"This is required information"})}/>
                        <ErrorMessageEvztField errors={errors} fieldName={"email"}/>
                    </div>
                    <div className="evzt-form-group">
                        <label htmlFor="gender">{t('formUserGender')}</label>
                        <select id="gender" className="evzt-custom-select" defaultValue={user.gender ?? user.gender} {...register("gender",{required:"This is required information"})}>
                            <option value="m">{t('formUserGenderMale')}</option>
                            <option value="f">{t('formUserGenderFemale')}</option>
                        </select>
                    </div>
                    <div className="evzt-form-group">
                        <label htmlFor="bith">{t('formUserBirth')}</label>
                        <input id="bith" type='date'  max={moment(maxDate).format("YYYY-MM-DD")} className="evzt-input-control" {...register("birth",{
                           required : true
                        })} />
                    </div>
                </div>
                {/* end row */}
                {/*<div className="evzt-form-row">
                    <div className="evzt-form-group">
                        <label htmlFor="home_town">Home town</label>
                        <input id="home_town" type='text' className="evzt-input-control" />
                    </div>
                    <div className="evzt-form-group">
                        <label htmlFor="current_town">Current town</label>
                        <input id="current_town" type='text' className="evzt-input-control" />
                    </div>
                    <div className="evzt-form-group">
                        <label htmlFor="occupation">Occupation</label>
                        <input id="occupation" type='text' className="evzt-input-control" />
                    </div>
                </div>*/}
                {/* end row */}
                <p className="evzt-form-title">{t("security")}</p>
                <div className="evzt-form-row">
                    <div className="evzt-form-group">
                        <InputPassword
                            inputName="new_password"
                            register={register}
                            validationRules={{}}
                            errors={errors}
                            placeHoldIt=""
                            inputClassList={["evzt-input-control"]}
                            label={t("newPassword")}
                            isVisiblePassword={isVisiblePassword}
                            setIsVisiblePassword={setIsVisiblePassword}
                        />
                        <ErrorMessageEvztField errors={errors} fieldName={"email"}/>
                    </div>
                    <div className="evzt-form-group">
                        <InputPassword
                            inputName="confirm_password"
                            register={register}
                            validationRules={{}}
                            errors={errors}
                            placeHoldIt=""
                            inputClassList={["evzt-input-control"]}
                            label={t('passwordConfirm')}
                            isVisiblePassword={isVisiblePassword}
                            setIsVisiblePassword={setIsVisiblePassword}
                        />
                    </div>
                    <div className="evzt-form-group">
                        <label htmlFor="current_town">{t('language')}</label>
                        <select className="evzt-input-control"  defaultValue={user.local ?? user.lang} {...register("local",{required:"This is required information"})} >
                            {LANGUAGES.map(({ code, label }) => (
                                <option key={code} value={code}>
                                    {label[user.local ?? user.lang]}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="evzt-text-center evzt-submit">
                    <Button type="submit" className="evzt-btn btn-primary"
                            variant="contained" disableElevation={true} disabled={isSubmit}>{t('btnUpdateProfil')}{isSubmit && <SpinerLoading/>}</Button>
                </div>
            </form>
        </>
    )
}
export default FormProfileBlock;