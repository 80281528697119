import {Icon} from "@mui/material";
import React, {useState} from "react";
import 'react-image-crop/dist/ReactCrop.css'
import ModalUploadImageProfile from "../Modal/ModalUploadImageProfile";

/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 01/02/2023
 * Time: 09:49
 */

const FormUploadProfile = ({user}) => {
    const inputUpload = React.useRef();
    const [imgSrc, setImgSrc] = useState('')
    const [openModalCrop, setOpenModalCrop] = useState(false);
    const browseClick = (ev) => {
        document.querySelector("#avatar_file").click()
    }
    const closeModalCrop = ()=>{
        setOpenModalCrop(false);
    }
    /**
     *
     * @param {Event} ev
     */
    const onChooseFile = (ev) => {
        /**
         * @var FileList files
         */
        const files = ev.target.files;
        if(files.length > 0){
            const reader = new FileReader()
            reader.addEventListener('load', () =>{
                    setImgSrc(reader.result?.toString() || '');
                    setOpenModalCrop(true);
                }
            )
            reader.readAsDataURL(files[0])
        }
    }
  return (
      <>
          <ModalUploadImageProfile user={user}
                                   open={openModalCrop}
                                   handleClose={closeModalCrop}
                                   imgSrc={imgSrc}
          />
          <div className="profile-avatar">
          <img src={user.avatar} alt="user-avatar" onClick={ev => browseClick(ev)}/>
          <form>
              <input accept="image/*" type="file" name="avatar_file" id="avatar_file"
                     className="avatar-file" ref={inputUpload}
                     onChange={onChooseFile } />
              <button className="btn-upload-image" type={"button"}
                      onClick={ev => browseClick(ev)}>
                  <Icon sx={{fontSize: 18}}>camera_alt</Icon>
              </button>
          </form>
          </div>
      </>
  )
}

export default FormUploadProfile