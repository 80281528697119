import React from 'react';
import {Link} from "react-router-dom";
import {displayUserFullName} from "../Utils/Common";
import {SERVER_BASE_URL} from "../Utils/Defines";

const ItemContactBlock = props => {
    const {userContact,openMoreInfos} = props
    return(
        <li>
            <Link to={"#"} className="contact-row" onClick={ev=>openMoreInfos(userContact)}>
                <img src={SERVER_BASE_URL + userContact.avatar} alt={displayUserFullName(userContact)}/>
                <div>
                    <span className="contact-row-fullname">{displayUserFullName(userContact)}</span>
                    <small>{userContact.email}</small>
                </div>
            </Link>
        </li>
    );
}
export default ItemContactBlock;