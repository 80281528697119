/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 30/06/2021
 * Time: 15:46
 */
import React from "react";
import eventImage from "../assets/image/wedding.jpg";
import {SERVER_BASE_URL} from "../Utils/Defines";
import {SvgIcon} from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Link } from "react-router-dom";
class ItemEvent extends React.Component{
    render() {
        const {event,redirectPage,onRemove,onManageGuest,onManageUpload,isGuest} = this.props
        return (
            <>
                <div id={'event-'+event.id} className={"evzt-card-box"}>
                    <div className="evzt-card">
                        <img src={event.bg_image === "" ? eventImage : SERVER_BASE_URL+(event.bg_image).substring(-1)} alt={event.name} className="evzt-card-image"/>
                        <div className="evzt-card-overlay">
                            <span className="btn-expand" title={"Expand"} onClick={ev => { onManageUpload(event) }}>
                                <svg aria-hidden="true" data-prefix="fas" data-icon="expand-alt"
                                     className="svg-inline--fa fa-expand-alt fa-w-14" xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 448 512"><path fill="#fff"
                                     d="M212.686 315.314L120 408l32.922 31.029c15.12 15.12 4.412 40.971-16.97 40.971h-112C10.697 480 0 469.255 0 456V344c0-21.382 25.803-32.09 40.922-16.971L72 360l92.686-92.686c6.248-6.248 16.379-6.248 22.627 0l25.373 25.373c6.249 6.248 6.249 16.378 0 22.627zm22.628-118.628L328 104l-32.922-31.029C279.958 57.851 290.666 32 312.048 32h112C437.303 32 448 42.745 448 56v112c0 21.382-25.803 32.09-40.922 16.971L376 152l-92.686 92.686c-6.248 6.248-16.379 6.248-22.627 0l-25.373-25.373c-6.249-6.248-6.249-16.378 0-22.627z"/>
                                </svg>
                            </span>
                        </div>
                        <div className="evzt-card-body">
                            <h3 className={"evzt-card-title"}>{event.name}</h3>
                        </div>
                        <div className="evzt-card-footer">
                            <div className={"evzt-card-footer-action"}>
                                <Link to={ (isGuest ? `/gallery-guest-event/` : `/gallery-event/`)+event.id } title="event details" style={{color: '#3aa65d'}} className={"btn-action btn-edit-event"}>
                                    <SvgIcon component={VisibilityIcon}></SvgIcon>
                                </Link>
                                {!isGuest &&
                                    <>
                                        <span title={"Edit event"} className={"btn-action btn-edit-event"} onClick={(ev) => redirectPage(`/edit-event/${event.id}`)}>
                                            <svg aria-hidden="true" data-prefix="fas" data-icon="edit"
                                            className="svg-inline--fa fa-edit fa-w-18"
                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor"
                                                                                            d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"/>
                                            </svg>
                                        </span>
                                        <span title={"Manage guest"} className={"btn-action btn-manage-guest"} onClick={ev=>onManageGuest(event)}>
                                            <svg aria-hidden="true" data-prefix="fas" data-icon="users"
                                            className="svg-inline--fa fa-users fa-w-20" xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 640 512"><path fill="currentColor"
                                                         d="M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6 40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z"/></svg>
                                        </span>
                                        <span title={"Delete"} className={"btn-action btn-delete-event"} onClick={ev => onRemove(event)} >
                                            <svg aria-hidden="true" data-prefix="fas" data-icon="trash"
                                            className="svg-inline--fa fa-trash fa-w-14"
                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor"
                                                                                            d="M432 32H312l-9.4-18.7A24 24 0 00281.1 0H166.8a23.72 23.72 0 00-21.4 13.3L136 32H16A16 16 0 000 48v32a16 16 0 0016 16h416a16 16 0 0016-16V48a16 16 0 00-16-16zM53.2 467a48 48 0 0047.9 45h245.8a48 48 0 0047.9-45L416 128H32z"/>
                                            </svg>
                                        </span>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
           </>
        )
    }
}

export default ItemEvent