/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 15/04/2023
 * Time: 11:49
 */
import {useTranslation} from "react-i18next";
import { Link } from "react-router-dom";

const PrivateMobileFooter = () => {
    const d = new Date()
    const {t} = useTranslation()
   return (
       <div id={"privateMobileFooter"}>
        <div className={"evzt-block-footer"}>
            <div className={"evzt-footer-container"}>
                <div className="links">
                    <ul className="link-list">
                        <li><Link to={"/about"} color="inherit">{t("about")}</Link></li>
                        <li><Link to={"/terms/services"} color="inherit">{t("termsOfService")}</Link></li>
                        <li><Link to={"/privacy/policy"} color="inherit">{t("privacy")}</Link></li>
                    </ul>
                </div>
            </div>
        </div>
         <div className={"evzt-copy-right"}>
             <div className={"evzt-copy-right-content"}>
                 <strong className={""}>
                    {d.getFullYear()}
                    &nbsp;<span className={"evzt-text-primary"}>&copy;</span> Lead Code Group</strong>
                 </div>
         </div>
       </div>
   )
}

export default PrivateMobileFooter;