/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 18/01/2023
 * Time: 17:23
 */
import { ErrorMessage } from "@hookform/error-message";
import {Icon} from "@mui/material"; 

const ErrorMessageEvztField=({errors,fieldName})=>{
       return  <ErrorMessage errors={errors} name={fieldName} render={
           ({message}) => <p className="input-feedback invalid"><Icon sx={{fontSize:14, mr:1/2}}>warningAmber</Icon>{message}</p>
       } />;
}
export default ErrorMessageEvztField;