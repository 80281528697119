/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 30/06/2021
 * Time: 12:10
 */
import { createSlice } from '@reduxjs/toolkit'
import {getUserConnected, removeDataSession, setUserSession} from "../Utils/Common";
import {DEFAULT_AVATAR} from "../Utils/Defines";

export const UserReducer = createSlice({
    name: 'user',
    initialState: {
        ...getUserConnected(),
        err :  null
    },
    reducers: {
        updateInformation: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.id = action.payload.id
            state.isLogged = action.payload.isLogged
            state.first_name = action.payload.first_name
            state.last_name = action.payload.last_name
            state.username = action.payload.username
            state.email = action.payload.email
            state.avatar = action.payload.avatar
            state.auth = action.payload.auth
            state.local = action.payload.local
            state.gender = action.payload.gender
            state.birth = action.payload.birth
            setUserSession(state)
        },
        updateAvatar:(state,action)=>{
            state.avatar = action.payload.avatar
            setUserSession(state)
        },
        addToken:(state,action) => {
            state.token = action.payload;
            state.err =  null;
        },
        logOut : (state,action) => {
            state.isLogged = false
            state.token = ""
            state.avatar=  DEFAULT_AVATAR
            state.first_name = ""
            state.last_name = ""
            state.username  = ""
            state.email     = ""
            state.auth      = ""
            state.gender    = ""
            state.birth    = ""
            removeDataSession();

        },
        setMessageErr:(state, action) => {
            state.err = action.payload.err
        },
        cleanMessageErr:(state, action) => {
            state.err =  null
        }
    }
})

// Action creators are generated for each case reducer function
export const { updateInformation,addToken,logOut, cleanMessageErr, setMessageErr,updateAvatar } = UserReducer.actions
export default UserReducer.reducer