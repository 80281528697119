import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import InputAdornment from '@mui/material/InputAdornment';
import { useTranslation } from "react-i18next";
import ErrorMessageEvztField from "../ErrorMessageEvztField";
import { onSubmitFormNewsLetter } from "../../EventHandler/OnSubmitEvent";
import { ModalAlertSuccess } from "../Modal/ModalAlertSuccess";
const FormNewsLetter = () => {
    const [successMessage,setSuccessMessage] = useState("");
    const {register, formState:{errors,isSubmitting},setError,handleSubmit,trigger} = useForm({});
    const {  t } = useTranslation();
    const m:string = t("messages.success.subscriptionNewsLetterDone")
    const validationRules = {
        "required" : t("formValidatorErrorMsgEmptyField")
    } 
    const onSubmittedFeedBack = (data:{err:any}) => {
        console.log(data)
         if(typeof data.err !=="undefined"){
            let mess:string = ""
             switch (data.err) {
                case "EMAIL_IS_ALREADY_IN_USE":
                    mess = t("messages.errors.emailAlreadyOnNewSletter");
                break;
                case "INCORRECT_EMAIL_ADDRESS":
                    mess = t("messages.errors.emailIncorrect");
                break;
                default:
                    mess = t("messages.errors.unknowErrorNewsLetter");
                break;
            }
            setError("email",{
                type : "manual",
                message: mess
            });
         }else{
            setSuccessMessage(m);
         }
    }
    useEffect(() => {
        if(successMessage.length > 0 )
            setSuccessMessage(m);
        if(typeof(errors) === "object" && Object.keys(errors).length > 0){
            trigger();
        }
    },[t,errors,m,trigger,setSuccessMessage,successMessage])
    return (
        <>
            {successMessage && <ModalAlertSuccess message={successMessage} openDefault={successMessage.length > 0} />}
            <form onSubmit={handleSubmit(d => onSubmitFormNewsLetter(d,onSubmittedFeedBack))} autoComplete="off">
                <Box sx={{ '& > :not(style)': {
                     m: 0,
                     minWidth: "90%"
                } }}>
                <TextField
                    
                    type="email"
                    disabled={isSubmitting}
                    id="input-with-icon-textfield"
                    label={t("form.newsletter.field.title")}
                    placeholder={t("form.newsletter.field.placeholder").toString()}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <AccountCircle sx={{ color:"#3aa65d", width: "24px"}} />
                            </InputAdornment>
                        ),
                    }}
                    sx={{ '& > :not(style)': { color:"inherit",fontSize:"inherit",fontFamily:"inherit"} }}
                    variant="standard"
                    color= "primary"
                    {...register("email",validationRules)}
                />
                 <ErrorMessageEvztField errors={errors} fieldName={"email"}/>
                </Box>
            </form>
        </>
    )
}

export default FormNewsLetter