/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 29/01/2023
 * Time: 16:45
 */
import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { displayUserFullName } from "../../Utils/Common";
import { AlternateEmailSharp } from "@mui/icons-material";
import { SERVER_BASE_URL } from "../../Utils/Defines";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function ModalInfoContact({
  open,
  handleClickOpen,
  handleClose,
  userContact,
}) {
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="contact-modal"
        maxWidth="lg"
      >
        <div className="evzt-contact-modal-container">
          <div className="evzt-border-wrapper">
            <div className="contact-modal-header">
              <button
                id="customized-dialog-title"
                onClick={() => handleClose()}
                className="btn-close"
                tabindex="0"
                type="button"
                aria-label="close"
              >
                <svg
                  class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="CloseIcon"
                >
                  <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                </svg>
              </button>
            </div>
            <DialogContent>
              <div className="contact-image">
                {userContact && (
                  <img
                    src={SERVER_BASE_URL + userContact.avatar.substring(-1)}
                    alt="Joshua Ortega"
                  />
                )}
              </div>
              <div className="contact-infos">
                <h2 className="evzt-text-secondary title">
                  {userContact ? displayUserFullName(userContact) : ""}
                </h2>
                {/* <Typography gutterBottom className="contact-item">
                            <Call fontVariant={"Filled"}  />
                            <label>+261340203300</label>
                        </Typography> */}
                <Typography gutterBottom className="contact-item">
                  <AlternateEmailSharp fontVariant={"Filled"} />
                  <label>{userContact ? userContact.email : ""}</label>
                </Typography>
                {/* <Typography gutterBottom className="contact-item">
                            <LocationOn fontVariant={"Filled"}  />
                            <label>Antananarivo-101</label>
                        </Typography> */}
              </div>
            </DialogContent>
          </div>
        </div>
      </BootstrapDialog>
    </div>
  );
}
