/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 28/01/2023
 * Time: 22:04
 */
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import {displayUserFullName} from "../../Utils/Common";

export default function ModalConfirmRemoveGuest({open,user,handleYes,handleNo}) {
    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {`Are you sure to detach`}<strong> {user && displayUserFullName(user)}</strong>{' in this event'} ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleNo}
                        variant={"contained"}
                        color={"info"}
                    >No</Button>
                    <Button
                        onClick={ ev => handleYes(user)}
                        autoFocus
                        variant={"contained"}
                        color={"error"} >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
