/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 29/01/2023
 * Time: 18:43
 */
import {Component} from "react";
import Header from "../Blocks/Header";
import {connect} from "react-redux";

import FormProfileBlock from "../Blocks/Form/FormProfileBlock";
import FormUploadProfile from "../Blocks/Form/FormUploadProfile";

class EditProfile extends Component{

    render() {
        const {user,countContact,countEvent} = this.props
        return (
            <>
                <Header/>
                <div className="evzt-container">
                <div className="profile-top-banner">
                    <FormUploadProfile user={user} />
                    <p className="username">@{user.username}</p>
                    <p className="pr-infos"><span>{countEvent}&nbsp;<strong>Events</strong></span><span>{countContact}&nbsp;<strong>Contacts</strong></span></p>
                    </div>
                    <FormProfileBlock user={user}/>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        user : state.user,
        countContact : state.contacts.count,
        countEvent : state.events.count,
    }
}

export default connect(mapStateToProps)(EditProfile)