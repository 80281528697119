import {EvztSlide, GenericSlide} from "yet-another-react-lightbox";
import ProgressiveImgLoader from '../Loaders/ProgressiveImgLoader';

declare module "yet-another-react-lightbox" {
    export interface EvztSlide extends GenericSlide {
        type: "evzt"|"image";
        origin:string;
        src:string;
        title: string;
        subtitle: string;
        tags:Array<string>;
        height:number;
        width:number;
    }
    interface SlideTypes {
        "evzt": EvztSlide;
    }
}

function RenderSlideCustom({slide}:{slide:EvztSlide}) {
    console.log(slide)
    return (
        <>
            <ProgressiveImgLoader 
                placeholderSrc={slide.src}
                src={slide.origin}
                alt={slide.title} 
                style={{width:`min(${slide.width}px,100%)`,maxHeight:`min(${slide.height}px,100%)`}} className={"yarl__slide_image"}
            />
        </>
    );
}

export default RenderSlideCustom;