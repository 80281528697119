import { useState, useEffect } from "react";

type ProgressiveImgLoaderType = {
    placeholderSrc:string,
    src:   string,
    alt :  string,
    style: any,
    className: string
}

const ProgressiveImgLoader = ({ placeholderSrc, src, ...props }:ProgressiveImgLoaderType) => {
  const [imgSrc, setImgSrc] = useState(placeholderSrc || src);
  console.log(props)
  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
        setImgSrc(src);
    };
  }, [src]);

  return (
    <img
      {...{ src: imgSrc, ...props }}
      alt={props.alt || ""}
      className={props.className}
    />
  );
};
export default ProgressiveImgLoader;