import {useState} from 'react';
import {Link} from "react-router-dom";
import {userSelector} from "../Store/Selectors";
import {useDispatch, useSelector} from "react-redux";
import {SERVER_BASE_URL} from "../Utils/Defines";
import Logout from '../Pages/Authentication/Logout';
import {displayUserFullName, getUserInServer} from "../Utils/Common";
import {addToken, updateInformation} from "../Store/UserReducer";
import {useTranslation} from "react-i18next";
import { BrowserView,MobileView } from 'react-device-detect';
function Header() {
    const [isShow, onToggleUser] = useState(false);
    const  user = useSelector (userSelector)
    const [needGetUserDb,setNeedGetUserDb] = useState(!user.isLogged);
    const dispatch = useDispatch()
    const {t}      = useTranslation()
    if(needGetUserDb){
        //@todo get User information if he isn't have
        getUserInServer().then(d => {
            setNeedGetUserDb(false)
             dispatch(addToken(user.token))
             dispatch(updateInformation(d))
        })
    }
    return (
        <header className={"header"}>
            <div className="header-container evzt-container">
                <Link to={"/"} className="header-brand">
                    <img src={SERVER_BASE_URL+"img/logo/light.png"} alt="logo" />
                </Link>
                <div className="header-right-side">
                    <div className="header-app-user">
                        <div className={"header-app-user-avatar"} onClick={()=>onToggleUser(!isShow)}>
                            <img src={user.avatar} alt={user.first_name+' '+user.last_name}/>
                            {isShow === false ? <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="currentColor"><path d="M8.12 9.29L12 13.17l3.88-3.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-4.59 4.59c-.39.39-1.02.39-1.41 0L6.7 10.7c-.39-.39-.39-1.02 0-1.41.39-.38 1.03-.39 1.42 0z"/></svg>
                                :
                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="currentColor"><path d="M8.12 14.71L12 10.83l3.88 3.88c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L12.7 8.71c-.39-.39-1.02-.39-1.41 0L6.7 13.3c-.39.39-.39 1.02 0 1.41.39.38 1.03.39 1.42 0z"/></svg>
                            }
                        </div>
                        <div className={`header-user-menu ${isShow === true ? "" : "evzt-d-none"}`}>
                            <ul className='menu-dropdown'>
                                <li>
                                    <Link to={"/my-profile"} className="header-user-menu-profile">
                                        <span>{displayUserFullName(user)}</span>
                                        <small>{"@"+user.username}</small>
                                    </Link>
                                </li>
                                <li className="divider"></li>
                                <BrowserView>
                                    {user.auth !== "USER_GUEST" &&
                                        <li>
                                            <Link to={"/"}>{t('events')}</Link>
                                        </li>
                                    }
                                </BrowserView>
                                <MobileView>
                                    <li>
                                        <Link  to={"/my-contacts"}>{t('contacts_more')}</Link>
                                    </li>
                                    <li>
                                        <Link  to={"/attended-events"}>{t('menu.mobiles.eventsImInvited')}</Link>
                                    </li>
                                    <li>
                                        <Link  to={"/my-events"}>{t('myEvents')}</Link>
                                    </li>
                                </MobileView>
                                <li className="divider"></li>
                                <li>
                                    <Logout wrapper={'span'} classList={'h-app-user-logout link'}/>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;