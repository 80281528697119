import {useForm} from "react-hook-form";
import React, {useEffect, useState} from "react";
import ErrorFeedBack from "../../Blocks/ErrorFeedBack";
import {Alert} from "@mui/material";
import {onSubmitFormRenewPassword} from "../../EventHandler/OnSubmitEvent";
import SpinerLoading from "../../Blocks/SpinerLoading";
import {Link, useHistory,useLocation} from "react-router-dom";
import {connect} from "react-redux";
import {addToken, cleanMessageErr, setMessageErr, updateInformation} from "../../Store/UserReducer";
import Authentication from "./Authentication";
import {withCookies} from "react-cookie";
import InputPassword from "../../Utils/InputPassword";
import axios from "axios";
import {API_USER_GET_INFO_FORGOT_PASSWORD} from "../../Utils/Defines";
import {displayUserFullName} from "../../Utils/Common";
import TransparentLogo from "../../Blocks/TransparentLogo";
import { LinkToCreateNewAccount } from "../../Blocks/LinkToCreateNewAccount";

/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 09/03/2023
 * Time: 17:42
 */

const RenewPassword = (prop) => {
    const {register, formState:{errors, isSubmitting}, handleSubmit} = useForm();
    const feedBackErr = prop.user.err;
    const [successMessage,setSuccessMessage] = useState(false);
    const [isVisiblePassword, setIsVisiblePassword] = useState(false);
    const [isLinkOk, setIsLinkOk] = useState(false);
    const [userConnected, setUserConnected] = useState(false);
    const [isLoadPage, setIsLoadPage] = useState(true);
    const history= useHistory();
    const location = useLocation();
    const queryParameters = new URLSearchParams(location.search)
    const majMessageSuccess = (data) => {
        const {success} = data
        if(success){
            prop.cleanMessageErr()
            setSuccessMessage("Password updated");
            setTimeout(() => history.push("/login"),1000)
        } else
            setSuccessMessage(false)
    }
    if(isLoadPage){
        axios.get(`${API_USER_GET_INFO_FORGOT_PASSWORD}?token=${encodeURIComponent(queryParameters.get("token"))}`).then(
            res => {
                const {data} = res
                setIsLinkOk(data.success);
                setUserConnected(data.user)
                setIsLoadPage(false)
            }
        ).catch( res => {
            console.log(res.error)
        }).finally( () => setIsLoadPage(false))
    }
    
    return (
        <>
            <div className="right-side">
                <p>{!isLoadPage  && userConnected && "Hello "+displayUserFullName(userConnected)+" !"}</p>
            </div>
            <div className="content-auth">
                {successMessage && <Alert severity="success">{successMessage}</Alert>}
                { (!isLoadPage && isLinkOk) && <ErrorFeedBack message={feedBackErr} classList={"evzt-alert error"} callBack={prop.cleanMessageErr}/> }
                <div className={'content-auth-form'}>
                    <TransparentLogo />
                    { !isLoadPage && isLinkOk
                        ?   <form id={"formForgotPassword"} onSubmit={handleSubmit((d)=>onSubmitFormRenewPassword(d,prop,majMessageSuccess,encodeURIComponent(queryParameters.get("token"))))}>
                                <div className="auth-input-form-group">
                                    <InputPassword
                                        inputName="password"
                                        register={register}
                                        validationRules={{required: 'This field is required'}}
                                        errors={errors}
                                        placeHoldIt="Password"
                                        isVisiblePassword={isVisiblePassword}
                                        setIsVisiblePassword={setIsVisiblePassword}
                                    />
                                </div>
                                <div className="auth-input-form-group">
                                    <InputPassword
                                        inputName="confirmPassword"
                                        register={register}
                                        validationRules={{required: 'This field is required'}}
                                        errors={errors}
                                        placeHoldIt="Confirm password"
                                        isVisiblePassword={isVisiblePassword}
                                        setIsVisiblePassword={setIsVisiblePassword}
                                    />
                                </div>
                                <button disabled={isSubmitting} type={"submit"} className="btn-submit submit-load">Save{isSubmitting && <SpinerLoading/>}</button>
                            </form>
                        : !isLoadPage && <p className={"evzt-text-white"}>Sorry,the link is disabled, please do the process again <Link to={'/forgot-password'}>here</Link>.</p>
                    }
                </div>
            </div>
            <div className="left-side">
               <LinkToCreateNewAccount />
            </div>
        </>
    );
}

const RenewPasswordStore = connect(
    (state) =>({
        user : state.user,
        err  : state.err
    }) ,
    (dispatch) => ({
        onSubmitLogin : user  => dispatch(updateInformation(user)),
        setToken      : token => dispatch(addToken(token)),
        formFeedBack  : err => dispatch(setMessageErr(err)),
        cleanMessageErr  : err => dispatch(cleanMessageErr(err))
    })
)(RenewPassword)

 export default Authentication(withCookies(RenewPasswordStore))