/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 30/06/2021
 * Time: 09:22
 */
import { configureStore,applyMiddleware } from '@reduxjs/toolkit'
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from "redux-thunk";
import UserReducer from "./UserReducer";
import ContactsReducer from "./ContactsReducer";
import EventsReducer from "./EventsReducer";
import EventsUserAttendedReducer from "./EventsUserAttendedReducer";

export default configureStore({
    reducer: {
        user     : UserReducer,
        contacts : ContactsReducer,
        events   : EventsReducer,
        eventsUserAttended   : EventsUserAttendedReducer,
    }
},composeWithDevTools(
    applyMiddleware(thunk),
    // other store enhancers if any
))
