import {changeFilterEvents} from "../Store/EventsReducer";
import {changeFilterEvents as changeFilterEventsGuest} from "../Store/EventsUserAttendedReducer";
/**
 *
 * @param ev
 * @param dispatch callback redux
 */
export const onChangeFilterEvents = (ev,dispatch) => {
    ev.preventDefault()
    dispatch(changeFilterEvents({"event":ev.target.value}))
}

/**
 *
 * @param ev
 * @param dispatch callback redux
 */
export const onChangeFilterEventsGuest = (ev,dispatch) => {
    ev.preventDefault()
    dispatch(changeFilterEventsGuest({"event":ev.target.value}))
}