/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 31/01/2023
 * Time: 10:33
 */
import {BootstrapDialog, BootstrapDialogTitle} from "./ModalCommonHead";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import * as React from "react";
import {useRef, useState} from "react";
import ReactCrop from "react-image-crop";
import {onUpload} from "../../EventHandler/OnClickEvents";
import {useDispatch} from "react-redux";
import {Alert, AlertTitle, Grid, Slider} from "@mui/material";
import SpinerLoading from "../SpinerLoading";
const TO_RADIANS = Math.PI / 180;
/**
 *
 * @param open
 * @param handleClose
 * @param {name:""} user
 * @return {JSX.Element}
 * @constructor
 */
const ModalUploadImageProfile = ({open,handleClose,user,imgSrc}) => {
    const dispatch    = useDispatch();
    const [crop, setCrop] = useState({
        unit: '%', // Can be 'px' or '%'
        x: 25,
        y: 10,
        width: 50,
        height: 50,
    });
    const [completedCrop, setCompletedCrop] = useState(null);
    const [scale, setScale] = useState(20);
    const [disabledSubmit, setDisabledSubmit] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const previewCanvasRef = useRef(null);
    const imgRef = useRef(null)
    const applyScale = (scale) => {
       setScale(scale);
    }
    const applyCrop = (c) => {
        if(
            completedCrop?.width &&
            completedCrop?.height &&
            imgRef.current &&
            previewCanvasRef.current
        ) {
            let file = null;
            setErrorMessage("");
            // We use canvasPreview as it's much faster than imgPreview.
            canvasPreview(
                imgRef.current,
                previewCanvasRef.current,
                completedCrop,
                scale / 10
            )
            previewCanvasRef.current.toBlob((blob) => {
                file = new File([blob], `${user.username}.jpeg`, { type: "image/jpeg" });
                console.log(file);
                setDisabledSubmit(true);
                onUpload(file,user.id,dispatch,setErrorMessageCallBack)
                    .then( d => {
                        setDisabledSubmit(false);
                        handleClose();
                    })
                    .catch(reason => console.log(reason))
            }, 'image/jpeg')

        }
    }
    const setErrorMessageCallBack = ({data}) => {
        if(typeof data.error !=="undefined"){
            setErrorMessage(data.error.message)
            console.log(data.error)
        }
        setDisabledSubmit(false);
    }
    const canvasPreview = (
        image,
        canvas,
        crop,
        scale = 1,
        rotate = 0,
    ) =>{
        const ctx = canvas.getContext('2d')

        if (!ctx) {
            throw new Error('No 2d context')
        }

        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height
        // devicePixelRatio slightly increases sharpness on retina devices
        // at the expense of slightly slower render times and needing to
        // size the image back down if you want to download/upload and be
        // true to the images natural size.
        const pixelRatio = window.devicePixelRatio
        // const pixelRatio = 1

        canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
        canvas.height = Math.floor(crop.height * scaleY * pixelRatio)

        ctx.scale(pixelRatio, pixelRatio)
        ctx.imageSmoothingQuality = 'high'

        const cropX = crop.x * scaleX
        const cropY = crop.y * scaleY

        const rotateRads = rotate * TO_RADIANS
        const centerX = image.naturalWidth / 2
        const centerY = image.naturalHeight / 2

        ctx.save()

        // 5) Move the crop origin to the canvas origin (0,0)
        ctx.translate(-cropX, -cropY)
        // 4) Move the origin to the center of the original position
        ctx.translate(centerX, centerY)
        // 3) Rotate around the origin
        ctx.rotate(rotateRads)
        // 2) Scale the image
        ctx.scale(scale, scale)
        // 1) Move the center of the image to the origin (0,0)
        ctx.translate(-centerX, -centerY)
        ctx.drawImage(
            image,
            0,
            0,
            image.naturalWidth,
            image.naturalHeight,
            0,
            0,
            image.naturalWidth,
            image.naturalHeight,
        )

        ctx.restore()
    }
    return (
        <>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title-manage-guest"
                open={open}
            >
                <BootstrapDialogTitle id="customized-dialog-title-manage-guest" onClose={handleClose}>
                   Upload new image
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        {!!imgSrc &&(
                            <ReactCrop crop={crop}
                                       maxWidth={350}
                                       maxHeight={350}
                                       minWidth={180}
                                       minHeight={180}
                                       onComplete={ c => setCompletedCrop(c)}
                                       circularCrop={true}
                                       keepSelection={true}
                                       ruleOfThirds={true}
                                       onChange={c => {
                                           setCrop(c)
                                       }}>
                                <img src={imgSrc} alt="user-avatar" ref={imgRef} style={{ transform: `scale(${scale/10})` }} />
                            </ReactCrop>
                         )
                        }
                        {!!completedCrop && (
                            <canvas
                                ref={previewCanvasRef}
                                style={{
                                    border: '1px solid black',
                                    objectFit: 'contain',
                                    width: completedCrop.width,
                                    height: completedCrop.height,
                                    display:'none'
                                }}
                            />
                        )}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={2} sx={{
                        "padding-left" : "9px",
                        "padding-right" : "9px",
                    }}>
                        <Grid item xs={10}>
                            <Slider defaultValue={scale}  onChange={ ev => applyScale(ev.target.value)} aria-label="Disabled slider" min={10} valueLabelDisplay="auto"/>
                        </Grid>
                        <Grid item xs={2} alignItems={true} sx={{"text-align":"right"}}>
                            <Button className="btn-submit submit-load" autoFocus onClick={applyCrop} variant={"contained"} disabled={disabledSubmit}>
                                Save
                                {disabledSubmit && <SpinerLoading/>}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </BootstrapDialog>
            {!!errorMessage &&
                (
                    <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        {errorMessage} — <strong>check it out!</strong>
                    </Alert>
                )
            }
        </>
    );
}

export default ModalUploadImageProfile