/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 23/06/2021
 * Time: 08:16
 */

import React from 'react';
import "../../assets/scss/pages/authentication.scss"
import PublicFooter from "../../Blocks/PublicFooter";

const Authentication = (FormRender) => {
    class Authentication extends React.Component{
        render() {
            return (
                <>
                    <section id={"authenticationBlock"} className="authentication-block">
                        <div className="authentication-content">
                            <FormRender />
                        </div>
                    </section>
                    <PublicFooter />
                </>
            )
        }
    }
    return Authentication
}

export default Authentication