import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import SocialShare from "../Pages/Event/SocialShare";
import { Download } from '@mui/icons-material';
import { Trans } from "react-i18next";
import { useCallback } from "react";
export const MenuSocialShare = ({style,urls}:{style:any,urls:any}) => {

    const downloadFiles = useCallback(() => {
        if(urls.length === 0) return;
        new Promise((resolve) => {
          const temporaryDownloadLink:HTMLAnchorElement = document.createElement("a");
          temporaryDownloadLink.style.display = "none";
          document.body.appendChild(temporaryDownloadLink);
          resolve(temporaryDownloadLink);
        })
          .then((temporaryDownloadLink:any) => {
            // return new Promise((resolve) => {});
            const downloadPromises = [];
            for (const [index, downloadLink] of urls.entries()) {
              const downloadPromise = new Promise((resolve) => {
                setTimeout(() => {
                  const fileName = downloadLink.split("/").pop();
                  temporaryDownloadLink.setAttribute("href", downloadLink);
                  temporaryDownloadLink.setAttribute("download", fileName);
                  temporaryDownloadLink.setAttribute("target", "_blank");
                  temporaryDownloadLink.click();
                  resolve(temporaryDownloadLink);
                }, 100 * index);
              });
              downloadPromises.push(downloadPromise);
            }
            return Promise.all(downloadPromises);
          })
          .then(([temporaryDownloadLink]:Array<any>) => {
            document.body.removeChild(temporaryDownloadLink);
          });
      }, []);

    return (
        <Paper sx={{ width: 200, maxWidth: '100%',...style }}>
          <MenuList>
            {/* <MenuItem>
                <ListItemIcon
                    key={"share-facebook"}
                    aria-label="share-facebook"
                    className="yarl__button"
                >
                    <SocialShare
                        provider="facebook"
                        url={urls.join(",")}
                    />
                </ListItemIcon>
              <ListItemText>Facebook</ListItemText>
            </MenuItem>
            <MenuItem>
                <ListItemIcon
                     key={"share-messenger"}
                     aria-label="share-messenger"
                     className="yarl__button"
                >
                    <SocialShare
                        provider="messenger"
                        url={urls.join(",")}
                    />
                </ListItemIcon>
              <ListItemText>Messenger</ListItemText>
            </MenuItem>
            <MenuItem>
                <ListItemIcon
                    key={"share-whatsapp"}
                    aria-label="share-whatsapp"
                    className="yarl__button"
                >
                    <SocialShare
                        provider="whatsapp"
                        url={urls.join(",")}
                    />
                </ListItemIcon>
              <ListItemText>WhatsApp</ListItemText>
            </MenuItem>
            <Divider /> */}
            <MenuItem onClick={downloadFiles}>
                <ListItemIcon
                        key={"share-whatsapp"}
                        aria-label="share-whatsapp"
                        className="yarl__button"
                    >
                    <Download fontSize='large' />
                </ListItemIcon>
                <ListItemText>
                    <Trans i18nKey={"buttons.download"} />
                </ListItemText>
            </MenuItem>
          </MenuList>
        </Paper>
      );
}