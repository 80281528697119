import {Link, useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {contactsCountSelector, eventsCountSelector, userSelector} from "../Store/Selectors";
import {displayUserFullName} from "../Utils/Common";
import MenuLeftNavigation from "./MenuLeftNavigation";
import {useTranslation} from "react-i18next";
/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 30/06/2021
 * Time: 15:28
 */
export default function ProfileBlock(){
    const  user = useSelector (userSelector)
    const countContact = useSelector(contactsCountSelector)
    const countEvent = useSelector(eventsCountSelector)
    const  display_name = displayUserFullName(user)
    const history = useHistory();
    const redirectPage = (path) => history.push(path);
    const {  t } = useTranslation();
    return (
        <div className="profile">
            <img className={"avatar"} src={user.avatar} alt={display_name}/>
            <h1 className={"profile-full-name"}><Link to={"/my-profile"}>{display_name}</Link></h1>
            <div className="profile-action">
                {user.auth !== "USER_GUEST" &&
                    <>
                        <div>
                            <Link to={""}>
                                <strong>{t('events',{count:countEvent})}</strong>
                                <span className="profile-action-content">{countEvent}</span>
                            </Link>
                        </div>
                        <div>
                            <Link to={""}>
                                <strong>{t('contacts',{count:countContact})}</strong>
                                <span className="profile-action-content">{countContact === '0' ? 'No contact' : countContact}</span>
                            </Link>
                        </div>
                    </>
                }
            </div>
            <div className="divider"></div>
            <MenuLeftNavigation redirectPage={redirectPage} user={user} t={t} />
        </div>
    )
}