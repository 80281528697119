/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 04/07/2021
 * Time: 10:57
 */

import React, {Component} from 'react';

class LoadingItemsVertical extends Component {
    render() {
        let loading = [];
        for (let i=0;i<this.props.count ; i++){
            loading.push(<div key={i} className={"vertical-loading-content"}>
                <span className={"avatar-loading"}></span>
                <div className={"description-loading"}>
                    <span></span>
                    <span></span>
                </div>
            </div>)
        }
        return (
            <div>
                {loading}
            </div>
        );
    }
}

export default LoadingItemsVertical;