import {SERVER_BASE_URL} from "../Utils/Defines";

/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 06/04/2023
 * Time: 16:40
 */

const TransparentLogo = () => {
    return (
        <>
            <div className="evzt-text-center">
                <div className="evzt-block-logo">
                    <img src={SERVER_BASE_URL+"img/logo/dark.png"} style={{
                    "height" : "75px",
                    "width"  : "auto",
                }} alt="logo" />
                </div>
            </div>
        </>
    )
}

export default TransparentLogo;