import PublicFooter from "../Blocks/PublicFooter";
import PublicHeader from "../Blocks/PublicHeader";
import { useTranslation } from "react-i18next";

const Contact = () => {
    const {t} = useTranslation();
    return (
        <>
            <PublicHeader />
            <section className="contact-us-content" id={"privacyBlock"}>
                <div className="contact-me">
                    <h1 className="title-contact">{t("ContactUs.Title")}</h1>
                </div>
                <div className="evzt-form-contact">
                    <form className="form-contact">
                        <div className="form-nameAndUsername">
                            <div className="evzt-form-contact">
                                {t("firstName")}
                                <input type="text" placeholder={t("firstName")}/>
                            </div>
                            <div className="evzt-form-contact">
                                {t("lastName")}
                                <input type="text" placeholder={t("lastName")}/>
                            </div>
                        </div>
                        <div className="form-Email evzt-form-contact">
                            {t("email")}
                            <input type="email" id="" placeholder={t("email")}/>
                        </div>
                        <div className="form-messageOrcomment evzt-form-contact">
                            {t("ContactUs.CommentOrMessage")}
                            <textarea id="messagemessage-to-send" placeholder={t("ContactUs.CommentOrMessage")}/>
                        </div>
                        <div className="evzt-btnSendMessage">
                            <button type="submit" className="evzt-btn" id="send">{t("ContactUs.Send")}</button>
                        </div>
                    </form>
                </div>            
            </section>
            <PublicFooter />
        </>
    )
}
export default Contact