/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 31/01/2023
 * Time: 10:33
 */
import {BootstrapDialog, BootstrapDialogTitle} from "./ModalCommonHead";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import Dropzone from "react-dropzone";
import ProgressBarLinear from "../../Utils/ProgressBarLinear";
import axios from "axios";
import {API_EVENT_UPLOAD_IMG, API_PICTURE_TMP_DELETE, MB_TO_BYTES} from "../../Utils/Defines";
import {CircularProgress, Fab, Grid, ListItem, ListItemAvatar} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import DeleteIcon from '@mui/icons-material/Delete';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItemIcon from "@mui/material/ListItemIcon";
import Box from "@mui/material/Box";
import {green} from "@mui/material/colors";
import {useTranslation} from "react-i18next";
import ModalConfirmCancelUpload from "./ModalConfirmCancelUpload";

/**
 *
 * @param open
 * @param {function} handleClose
 * @param {name:""} event
 * @return {JSX.Element}
 * @constructor
 */
const ModalUploadImageEvent = ({open,handleClose,event}) => {
    const [progressionList,setProgressionList] = useState([]);
    const [filesToUpload,setFilesToUpload] = useState(null);
    const [readedFiles,setReadedFiles] = useState([]);
    const [isUploadRunning,setIsUploadRunning] = useState(false);
    const [currentProgression,setCurrentProgression] = useState(0);
    const [openModalConfirmCancelUpload,setOpenModalConfirmCancelUpload] = useState(false);
    const [posToRemove,setPosToRemove] = useState(-1);
    const {  t } = useTranslation();
    const handleDropDrag = async (files) => {
        if(!isUploadRunning){
            setFilesToUpload((prevState) => {
                const tokenControllers = []
                files.forEach((f,p)=> tokenControllers.push(axios.CancelToken.source()));
                return {tokenControllers:tokenControllers,files:files};
            })
        }
        return files
    }
    /**
     *
     * @param item
     * @param pos
     */
    const onRemovePicture=(item,pos)=>{
        if(!isUploadRunning && parseInt(posToRemove)===-1){
            setFilesToUpload([])
            setPosToRemove(pos );
            return axios.get(API_PICTURE_TMP_DELETE.replace("{id}",item.id))
                .then(function (response) {
                    const {data} = response
                    console.log("Remove this pos:"+pos)
                    setReadedFiles((prevState) => prevState.filter(fileReaded => fileReaded.id !== item.id));
                    setProgressionList((prevState) => prevState.filter((it,p) => p !== pos));
                    setPosToRemove(-1 );
                    return data.success
                })
                .catch(function (error) {
                    return error.response
                });
        }
    }
    const uploadOnServer = useCallback(async (file,tokenController) => {
        const formData = new FormData();
        formData.append(
            "picture_file",
            file
        );
        formData.append("folder_name",event.folders[0].physical_name)
        const options = {
            cancelToken: tokenController.token,
            onUploadProgress :({loaded,total}) => {
                const percent =  Math.floor((loaded * 100) / total)
                setCurrentProgression(percent)
                setProgressionList((progressListOLd) => {
                    const currentPos = progressListOLd.findIndex( val => val < 100)
                    console.log({"positionZao" : currentPos})
                    progressListOLd[currentPos] = percent
                    return progressListOLd
                })
            }
        }
        return axios.post(API_EVENT_UPLOAD_IMG,formData,options)
            .then(function (response) {
                const {data} = response
                return data.success
            })
            .catch(function (error) {
                return error.response
            });
    }, [event])
    const startUpload = useCallback(async (filesToUpload) => {
        const total = filesToUpload.files.length
        let currentPos = 0;
        const successUpload = [];
        setIsUploadRunning(true);
        // console.log(filesReaded.length)
        while (total > currentPos) {
            const file              =  filesToUpload.files[currentPos];
            const tokenController   =  filesToUpload.tokenControllers[currentPos];
            if (file) {
                try {
                    const {id} = await uploadOnServer(file,tokenController);
                    successUpload.push(id);
                } catch (error) {
                    console.log(error)
                }
            }
            currentPos++;
        }
        successUpload.forEach( id =>{
            setReadedFiles((prevState) => {
                const currentPos = prevState.findIndex( item => parseInt(item.id) === 0)
                prevState[currentPos].id = id;
                return prevState;
            });
        })
        setIsUploadRunning(false)
    },[uploadOnServer])

    useEffect(() => {
        if(filesToUpload){
            const promises = [];
            console.log(filesToUpload)
            filesToUpload.files.map((file,key) => {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(file);
                const promise = new Promise((resolve, reject) =>{
                    fileReader.onload= (ev) => {
                        resolve({
                            "file" : file,
                            "blob" : ev.currentTarget.result,
                            "id" : 0
                        })
                    }
                    fileReader.onerror = ev => reject(ev)
                })
                promises.push(promise)
                return file;
            })
            Promise.all(promises).then(filesReaded => {
                setReadedFiles((prevState) => [...prevState,...filesReaded]);
                setProgressionList((prevState) => {
                    const nextState = Array.from({length:filesReaded.length}, () => 0  );
                    console.log("Next list progression",nextState);
                    return [...prevState,...nextState]
                });
                startUpload(filesToUpload).then(r => console.log(r))
            })
        }
    },[filesToUpload,startUpload]);

    const resetStates = () => {
        setReadedFiles([])
        setCurrentProgression(0)
        setIsUploadRunning(false)
        setProgressionList([])
        setFilesToUpload(null)
    }

    const handleCloseThis=()=>{
        if(isUploadRunning)
            setOpenModalConfirmCancelUpload(true)
        else
            handleClose()
    }

    const handleConfirmCancelUpload=()=>{
        if(filesToUpload){
            if(filesToUpload.tokenControllers.length > 0){
                filesToUpload.tokenControllers.forEach(stopUploadAjax)
            }
        }
        resetStates();
        handleClose();
        setOpenModalConfirmCancelUpload(false)
    }
    /**
     * 
     * @param {axios.CancelToken.source()} source 
     * @param number pos
     */
    const stopUploadAjax = (source,pos) => {
        if(source){
            source.cancel('Operation canceled by the user.');
            setFilesToUpload((prevState) => {
                if(typeof prevState.tokenControllers !=="undefined"){
                    prevState.tokenControllers = prevState.tokenControllers.filter((token,p) => p !== pos)
                }
                if(typeof prevState.files !=="undefined"){
                    prevState.files = prevState.files.filter((file,p) => p !== pos)
                }
                return prevState
            })
        }
    }

    /*const cancelUploadByItem = (item,pos) => {
        if(filesToUpload 
            && filesToUpload.tokenControllers.length > 0 
            && typeof filesToUpload.tokenControllers[pos] !== "undefined"
        ){
            stopUploadAjax(filesToUpload.tokenControllers[pos],pos)
        }
    }*/

   // console.log(currentProgression)
    console.log("progress =>> ",progressionList.findIndex(value => value === currentProgression))
    return (
        <div>
            <BootstrapDialog
                onClose={()=>{}}
                aria-labelledby="customized-dialog-title-manage-guest"
                open={open}
                className="modal-event-dropzone"
            >
                <BootstrapDialogTitle id="customized-dialog-title-manage-guest"
                disabled={isUploadRunning}
                onClose={handleCloseThis}>
                  {t('form.upload.title',{"event_name":(event ? event.name : "")})}
                </BootstrapDialogTitle>
                <DialogContent>
                <Dropzone
                    disabled={isUploadRunning}
                    accept={{'image/*': ['.png','.jpeg', '.jpg']}}
                    onDropAccepted={(files) => handleDropDrag(files)}>
                    {({getRootProps, getInputProps}) => (
                        <section>
                        <div {...getRootProps()} className="dropzone">
                            <input {...getInputProps()} />
                            <p>{
                                isUploadRunning ? t("form.upload.message.inprogress"):
                                    t("form.upload.message.dragfiles")
                            }</p>
                        </div>
                        </section>
                    )}
                </Dropzone>
                </DialogContent>
                <DialogActions>
                    <Grid container rowSpacing={1}>
                        <List sx={{
                            width: '100%',
                            bgcolor: '#c6c6c6',
                            position: 'relative',
                            overflow: 'auto',
                            maxHeight: 250,
                            padding : readedFiles.length > 0 ? '8px 0 8px 0': '0px',
                            margin : '0 8px 0 8px'
                        }} >
                            {readedFiles.map((item,k )=> {
                                const imgBlob = item.blob
                                const file = item.file
                                const progress = progressionList[k] ?? 0
                                const borderBottom = readedFiles.length-1 > k ? "2px solid #1a582f" : "0px";
                                const el =  (
                                    <ListItem
                                        sx={
                                            {
                                                borderBottom: borderBottom,
                                                bgcolor: "#fdfdfd",
                                                margin: "0 8px 0 8px",
                                                width: "96%"
                                            }
                                        }
                                        secondaryAction={
                                            <>
                                                <Box sx={{
                                                    position:"relative",
                                                    left : "25%",
                                                    display: isUploadRunning ? "none" : "inherit"
                                                }}>
                                                   <Fab
                                                        aria-label="delete"
                                                        sx ={{
                                                            minHeight: "unset",
                                                            width:"28px",
                                                            height:"28px"
                                                        }}
                                                    >
                                                        <DeleteIcon
                                                            sx ={{
                                                                fontSize: 16
                                                            }}
                                                            onClick={ ev => onRemovePicture(item,k)} disabled={isUploadRunning}/>
                                                    </Fab>
                                                    {posToRemove === k && (
                                                        <CircularProgress
                                                            size={35}
                                                            sx={{
                                                                color: green[500],
                                                                position: 'absolute',
                                                                top: -4,
                                                                left: -4,
                                                                zIndex: 1,
                                                            }}
                                                        />
                                                    )}
                                                </Box>
                                            </>
                                        }
                                    >
                                        <ListItemAvatar>
                                            {imgBlob !== "" && <Avatar src={imgBlob} alt={file.name}/>}
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={file.name}
                                            secondary={(file.size / MB_TO_BYTES).toFixed(2) + " MB"}
                                        />
                                        <ListItemIcon sx={{width:"150px",marginLeft:"10px",marginRight:"15px"}}>
                                            <ProgressBarLinear value={progress}/>
                                        </ListItemIcon>
                                    </ListItem>
                                )
                                return React.cloneElement(el, {
                                    key: k,
                                })
                            })}
                        </List>
                    </Grid>
                    {/*<Button autoFocus onClick={ev=>{*/}
                    {/*    setProgression(0);*/}
                    {/*    handleClose();*/}
                    {/*}} variant={"contained"}>*/}
                    {/*    Close*/}
                    {/*</Button>*/}
                </DialogActions>
            </BootstrapDialog>
             <ModalConfirmCancelUpload open={openModalConfirmCancelUpload} handleNo={()=>setOpenModalConfirmCancelUpload(false)} handleYes={handleConfirmCancelUpload} />
        </div>
    );
}

export default ModalUploadImageEvent