import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import EvtRouter from './EvztRouter';
import Store from './Store/Store'
import { Provider } from 'react-redux'
//styles
import "./assets/scss/global.scss";
import "./assets/scss/partials/_header.scss"
import {CookiesProvider} from "react-cookie";
import "./Utils/i18n"
import EvztTheme from './evzt-theme';
import { ThemeProvider } from '@mui/material/styles';
import { LoadingIcon } from 'yet-another-react-lightbox';

// loading component for suspence fallback
const Loader = () => (
  <div className="App" style={{width:"100%",textAlign:"center",height:"calc(100vh)",backgroundColor:"#0000004d"}}>
    <LoadingIcon style={
      {"position" : "absolute",
        "top":"50%",
        "color":"#3aa65d"
      }
    } />
  </div>
);

ReactDOM.render(
    <Provider store={Store}>
      <ThemeProvider theme={EvztTheme}>
        <Router>
          <CookiesProvider>
          <Suspense fallback={<Loader />}>
            <EvtRouter />
          </Suspense>
          </CookiesProvider>
        </Router>
      </ThemeProvider>
    </Provider>,
  document.getElementById('evzt')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
