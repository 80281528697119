import React from "react";
import {useTranslation} from "react-i18next";

function SearchBlock({events,dispatch,onChangeFilterEvents}) {
    const {  t } = useTranslation();
    return(
        <div className="search-block event-filter">
            <form id={"searchForm"} >
               <div>
                   {/*<InputSearchBlock/>*/}
                   <div className="select-wrapper">
                       <select className="evzt-custom-select"
                               defaultValue={events.filters.event ?? "ev-name-asc"}
                               id={"eventSearchFilter"} onChange={ ev => onChangeFilterEvents(ev,dispatch)}>
                           <optgroup label={t("filter.event.name")}>
                               <option value={"ev-name-asc"}>{t("filter.increasing")}</option>
                               <option value={"ev-name-desc"}>{t("filter.decreasing")}</option>
                           </optgroup>
                           <optgroup label={t("formEventDate")}>
                               <option value={"ev-date-desc"}>{t("filter.date.most")}</option>
                               <option value={"ev-date-asc"}>{t("filter.date.older")}</option>
                           </optgroup>
                       </select>
                   </div>
               </div>
            </form>
        </div>
    );
}

export default SearchBlock;