import {
    API_BASE_URL,
    API_EVENT_ATTACH_USERS,
    API_EVENT_DELETE,
    API_EVENT_INFO, API_EVENT_PICTURES, API_GUEST_EVENTS,
    API_NEWS_LETTER_UNSUBSCRIPTION,
    API_PICTURE_RELATED,
    API_USER_CONTACT,
    API_USER_EVENTS,
    API_USER_GET, DEFAULT_AVATAR, DEFAULT_LANG, SERVER_BASE_URL
} from "./Defines";

/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 01/07/2021
 * Time: 06:05
 */

const axios = require('axios');

// return the user data from the session storage
export const getUser = () => {
    const userStr = sessionStorage.getItem('user');
    if (userStr) return JSON.parse(userStr);
     else  return null;
    //     console.log(axios.defaults)
    //     const userConnected = await axios.get(API_USER_GET)
    //         .then(function (response) {
    //             const {data} = response
    //             data.avatar = SERVER_BASE_URL + data.avatar.substring(1)
    //             setUserSession(data)
    //             return data
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         })
    //     console.log(userConnected)
    //     return userConnected;
    // }


}
/**
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export const getUserInServer = async () => {
        let token = getToken()
        if(!token) return null;
        axios.defaults.headers.common['Authorization'] = "Bearer "+token.access_token;
        const userConnected = await axios.get(API_USER_GET)
            .then(function (response) {
                const {data} = response
                data.avatar = SERVER_BASE_URL + data.avatar.substring(1)
                //setUserSession(data)
                return data
            })
            .catch(function (error) {
                console.log(error);
            })
        console.log(userConnected)
        return userConnected;
}

// return the token from the session storage
/**
 *
 * @returns {null|any} {"access_token":"","expires_in":10368000,"token_type":"bearer","scope":null,"refresh_token":"ZTUzNzdiNTU1ZjMyYTM4MWE4NDI3YmUyMzM1N2I0NDUxMTUyNjE1ZmQwMjBiOTMxOGNkMjAyZjkxNTlkYjRmZg","expires_time":1635484279}
 */
export const getToken = () => {
    const tokenStr = sessionStorage.getItem('token');
    if (tokenStr && tokenStr !=="undefined") return JSON.parse(tokenStr);
    else return null;
}

// remove the token and user from the session storage
export const removeUserSession = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('contacts');
    sessionStorage.removeItem('events');
    sessionStorage.removeItem('eventsUserAttended');
    return true;
}

// remove the token and user from the session storage
export const removeDataSession = () => {
    removeUserSession();
    removeEventsInSession();
    removeContactsInSession();
}

// set the token from the session storage
export const setTokenSession = (token, user) => {
    sessionStorage.setItem('token', JSON.stringify(token));
}
// set the user from the session storage
export const setUserSession = (user) => {
    sessionStorage.setItem('user', JSON.stringify(user));
}


// set the user from the session storage
export const getUserLang = () => {
    const currentUser = getUserConnected();
    //console.log(currentUser)
    return !currentUser.isLogged ?  (
        sessionStorage.getItem('_local') ?? DEFAULT_LANG) : currentUser.local
}

// set the user lang from the session storage
export const setUserLang = (local) => {
    const currentUser = getUserConnected();
    if(!currentUser.isLogged)
        sessionStorage.setItem('_local',local)
    changePathApiLanguage()
}

/**
 *
 * @returns {Object}
 */
export const getUserConnected=() => {
    let user = getUser()
    let token = getToken()
    //console.log(user,token)
    if (user) {
        user.isLogged = true
    } else {
        user = {
            username: '',
            first_name: '',
            last_name: '',
            avatar: DEFAULT_AVATAR,
            token: {},
            isLogged: false,
            lang    : DEFAULT_LANG
        }
    }
    if (token && !isExpireToken(token))
        user.token = token
    else {
        user.token = {}
        removeUserSession()
    }
    return user
}

/**
 *
 * @returns {number}
 */
export const getTimestamp= () =>{
    let d = new Date()
    return Math.floor(d.getTime()/1000)
}
/**
 *
 * @returns {boolean}
 */
export const isExpireToken = (token) => {
    let res = true
    if(token){
        console.log("Token restant ==> "+Math.round((token.expires_time-getTimestamp())/86400)+" jour(s)")
        res = token.expires_time < getTimestamp()
    }
    return res
}
/**
 *
 * @return {*|{pagination: {next: string, prev: string}, count: number, onLoad: boolean, items: *[]}}
 */
export const getEventsInSession = () => {
    return getCommonListInSession('events');
}
/**
 *
 * @return {*|{pagination: {next: string, prev: string}, count: number, onLoad: boolean, items: *[]}}
 */
export const getEventsUserAttendedInSession = () => {
    return getCommonListInSession('eventsAttended');
}

export const removeEventsInSession = () => {
    return sessionStorage.removeItem('events');
}

export const removeContactsInSession = () => {
    return sessionStorage.removeItem('contacts');
}
/**
 *
 * @return {*|{pagination: {next: string, prev: string}, count: number, onLoad: boolean, items: *[]}}
 */
export const getContactsInSession = () => {
    return getCommonListInSession('contacts');
}

/**
 *
 * @param {string} name
 * @return {any|{pagination: {next: string, prev: string}, count: number, onLoad: boolean, items: *[]}}
 */
export const getCommonListInSession = (name) => {
    const eventsStr = sessionStorage.getItem(name);
    if (eventsStr) return {onLoad: false,...JSON.parse(eventsStr)};
    else return {
        onLoad: true,
        items : [],
        count : 0,
        pagination:{
            next : '',
            prev : ''
        },
        filters:{
            "event" : "ev-name-asc"
        }
    };
}
/**
 *
 * @param {Object} user
 */
export const displayUserFullName = (user) =>{
    if(typeof user.first_name === "undefined")
        throw new Error('User can not be undefined firstname or lastname');
    return user.first_name + " "+user.last_name
}

/**
 *
 * @param params
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getContacts = (params={next:1,prev:0,perPage:4}) => {
   return getDataPaginate(API_USER_CONTACT,params)
}

/**
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getEvents = (params={next:1,prev:0,perPage:3,filters:null}) => {
    return getDataPaginate(API_USER_EVENTS,params);
}

/**
 *
 * @returns {Promise<AxiosResponse<any>>}
 */
export const getEventsGuest = (params={next:1,prev:0,perPage:3,filters:null}) => {
    return getDataPaginate(API_GUEST_EVENTS,params);
}

/**
 *
 * @param urlApi
 * @param params
 * @return {Promise<AxiosResponse<any>>}
 */
export const getDataPaginate = (urlApi,params={next:1,prev:0,perPage:2,filters:null}) => {
    const page = params.next !== 0 ? params.next : params.prev !== 0 ? params.prev : 1;
    urlApi += "?per_page="+params.perPage+"&page="+page;
    if(typeof params.filters === "object"){
        urlApi += "&filters="+JSON.stringify(params.filters);
    }
    return  axios.get(urlApi)
        .then(function (response) {
            const {data} = response
            return data
        })
        .catch(function (error) {
            console.log(error);
            return null
        })
}
/**
 *
 * @param {int} id
 * @return {Promise<AxiosResponse<any>>}
 */
export const getEventById = (id) => {
    return  axios.get(API_EVENT_INFO.replaceAll("{id}",id))
        .then(function (response) {
            const {data} = response
            return data
        })
        .catch(function (error) {
            console.log(error);
            return null
        });
}

/**
 *
 * @param {int} id
 * @return {Promise<AxiosResponse<any>>}
 */
export const deleteEventById = (id) => {
    return  axios.get(API_EVENT_DELETE.replaceAll("{id}",id),{method:"DELETE"})
        .then(function (response) {
            const {data} = response
            return data
        });
}

/**
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export const getRelatedImages = () => {
    return  axios.get(API_PICTURE_RELATED.replaceAll("{limit}",12),)
        .then(function (response) {
            const {data} = response
            return data
        })
        .catch(function (error) {
            console.log(error);
            return null
        });
}

/**
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export const getEventsImages = (idEvent,params) => {
    const page = params.next !== 0 ? params.next : params.prev !== 0 ? params.prev : 1;
    const urlApi = API_EVENT_PICTURES.replaceAll("{id}",idEvent)+ "?per_page="+params.perPage+"&page="+page;
    return  axios.get(urlApi)
        .then(function (response) {
            const {data} = response
            return data
        })
        .catch(function (error) {
            console.log(error);
            return null
        });
}

/**
 *
 * @param event
 * @param users
 * @param {Function} formFeedBack
 * @return {Promise<void>}
 */
export const onAttachUserInEvent = async (event,users,formFeedBack) => {
    const user = getUserConnected();
    if (user) {
        event["event_invitations"] = users.map( item => ({ event:{id:event.id}, user:{id:item.id} }) )
        await axios.post( API_EVENT_ATTACH_USERS, event)
            .then( (response) => {
                let {data} = response
                console.log('e:',data)
                if (typeof data.error !== "undefined") {
                    console.log('e:',data.error_description)
                    // dispatch.formFeedBack({err: data.error_description});
                } else {
                    formFeedBack(data);
                }
            })
            .catch(function (error) {
                const response = error.response.data.error;
                let message = "";
                console.log(response)
                if(typeof response.exception !=="undefined"){
                    console.log(response.exception)
                    response.exception.forEach((item)=>{
                        message += `<p>${item.message}</p>`;
                    })
                }
                formFeedBack({err: message});
            }).finally((apiRes) =>{
                console.log(apiRes);
            })
    }
}

export const displayContainError = (error) => {
    if(typeof error.response !== "undefined"){
        const {data} = error.response
        if(typeof data.error !== "undefined"){
            return data.error.message
        }
    }
    return null
}

export const changePathApiLanguage=()=>{
    axios.defaults.baseURL = API_BASE_URL.replaceAll('{_locale}',getUserLang());
}

/**
 * 
 * @param {String} token 
 * @param {function} formFeedBack 
 * @returns 
 */
export const onUnSubscribeNewsLetter = async (token,formFeedBack) => {
    const data = {token:token,client_uri:window.location.origin}
    return await axios.post( API_NEWS_LETTER_UNSUBSCRIPTION, data)
        .then( (response) => {
            let {data} = response
            console.log('is ok:', data)
            if (typeof data.error !== "undefined") {
                console.log('e:',data.error_description)
                formFeedBack({err: data.error_description});
            } else {
                formFeedBack(data);
            }
        })
        .catch(function (error) {
            let message = "Uknow error";
            if(typeof error !=="undefined" && typeof error.hasOwnProperty("response")){
                console.log(error.response)
                if(typeof error.response !=="undefined" && typeof error.response.hasOwnProperty("errors")) {
                    message = error.response.data;
                }
            }
            formFeedBack({err: message});
        }).finally((apiRes) =>{
            console.log(apiRes);
        })
}

/**
 * 
 * @param {Object} mappingKeys 
 * @param {Object} dataSource 
 * @returns {Object}
 */
export const retrievePersonalInformations = (mappingKeys,dataSource) =>{
    let data = {};
    let user_information = {};
    for (let k in mappingKeys  ) {
        const realKeyName = mappingKeys[k];
        if(Object.keys(dataSource).indexOf(k) > -1){
            if(realKeyName !== "social_net_work_identify")
                user_information[realKeyName] = dataSource[k]
            else
                data[realKeyName] = dataSource[k]
        }
        
    }
    data["user"] = user_information;
    return data;
}