import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import CircularProgress from '@mui/joy/CircularProgress';

const SimpleLoadingText = () => {
    return (
        <>
            <Box sx={{ display: 'flex', gap: 2, flexDirection:'column',alignItems: 'center', flexWrap: 'wrap' }}>
                <Button startDecorator={<CircularProgress variant="solid" />}>Loading…</Button>
            </Box>
        </>
    )
}

export default SimpleLoadingText