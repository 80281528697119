/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 02/07/2021
 * Time: 22:51
 */
import { createSlice } from '@reduxjs/toolkit'
import {getContactsInSession} from "../Utils/Common";
import {NBR_CONTACT_PER_PAGE} from "../Utils/Defines";

export const ContactsReducer = createSlice({
    name: 'contacts',
    initialState: {
        ...getContactsInSession()
    },
    reducers: {
        putToList: (state, action) => {

            if(typeof action.payload.items !=="undefined"){
                state.items = [...state.items,...action.payload.items]
                state.pagination = action.payload.pagination
                if(state.items.length > state.count)
                    state.count = state.items.length
            }else{
                const user = action.payload
                const posExist = state.items.findIndex((currentUser) => currentUser.id === user.id)
                if(posExist < 0){
                    state.items.push(action.payload);
                    state.count += 1;
                }
            }

            sessionStorage.setItem('contacts', JSON.stringify({
                items : state.items,
                count : state.count,
                pagination : state.pagination
            }));
        },

        setContactInfos : (state,action) => {
            state.items = action.payload.items
            state.count = action.payload.count
            state.pagination = action.payload.pagination
            sessionStorage.setItem('contacts', JSON.stringify({
                items : state.items,
                count : state.count,
                pagination : state.pagination
            }));
        },

        setContactLoading : (state,action) => {
            state.onLoad = action.payload
        },
        resetContacts : (state,action) => {
            state.onLoad = true;
            state.items = [];
        },
        refactContactToDefaultList : (state,action) =>{
            state.items = state.items.slice(0,NBR_CONTACT_PER_PAGE);
            state.pagination.next = state.count > NBR_CONTACT_PER_PAGE ? 2 : 1;
            sessionStorage.setItem('contacts', JSON.stringify({
                items : state.items,
                count : state.count,
                pagination : state.pagination
            }));
        },
        refreshContacts:(state,action) => {
            const k = state.items.findIndex((d,k) => d.id === action.payload.id)

            state.items[k] = action.payload
            sessionStorage.setItem('contacts', JSON.stringify({
                items : state.items,
                count :  state.count,
                pagination : state.pagination
            }));
        },
    }
})

// Action creators are generated for each case reducer function
export const { putToList,setContactInfos,setContactLoading,resetContacts,refactContactToDefaultList,refreshContacts } = ContactsReducer.actions
export default ContactsReducer.reducer