import {onClickLoadMoreEventsGuest} from "../EventHandler/OnClickEvents";
import {NBR_EVENT_PER_PAGE} from "../Utils/Defines";
import IconButton from "@mui/material/IconButton";
import {green} from "@mui/material/colors";
import ResetIcon from "@mui/icons-material/Refresh";
import LoadingItemsHorizontal from "./LoadingItemsHorizontal";
import React from "react";
import {eventsGuestOnLoadSelector, eventsGuestSelector} from "../Store/Selectors";
import {useDispatch, useSelector} from "react-redux";
import {getEventsGuest} from "../Utils/Common";
import ItemEvent from "./ItemEvent";
import {refactToDefaultList,setEventInfos,setEventGuestLoading} from "../Store/EventsUserAttendedReducer";
import SearchBlock from "./SearchBlock";
import {onChangeFilterEventsGuest} from "../EventHandler/onChangeInputEvent";
import {useTranslation} from "react-i18next";
import { BrowserView,MobileView } from 'react-device-detect';

/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 17/03/2023
 * Time: 12:09
 */

const EventsGuestBlock = () => {
    const events = useSelector(eventsGuestSelector)
    const isLoad      = useSelector(eventsGuestOnLoadSelector)
    const dispatch = useDispatch()
    const {t}      = useTranslation()
    const paramsGet = {
        next:1,
        prev:0,
        perPage:NBR_EVENT_PER_PAGE,
        filters : events.filters
    }
    if(isLoad && events.count === 0){
        getEventsGuest(paramsGet).then((data)=> {
            if (data) {
                dispatch(setEventInfos(data))
                console.log(data)
            }

        }).catch((error) => {
            console.log(error)
        }).finally(()=>{
            dispatch(setEventGuestLoading(false))
        })
    }
    const onResetList = () => dispatch(refactToDefaultList())
    const total_line = Math.ceil(events.items.length / NBR_EVENT_PER_PAGE)
    let renderEvents = [];
    let nextSlice = 0;
    let endSlice = NBR_EVENT_PER_PAGE;
    for (let i = 0; i < total_line ; i++) {
        const newItemsEvents = events.items.slice(nextSlice,endSlice)
        const rows = newItemsEvents.map(
            (event,pos) => <ItemEvent
                event={event} key={`event-${pos}`}
                redirectPage={()=>{}}
                onManageGuest={()=>{}}
                onManageUpload={()=>{}}
                onRemove={()=>{}} isGuest={true}/>)
        renderEvents.push(rows);
        nextSlice += NBR_EVENT_PER_PAGE
        endSlice += NBR_EVENT_PER_PAGE
    }
      return (
          <>
              <h2>{t('eventsImInvited')}</h2>
              <SearchBlock dispatch={dispatch} events={events} onChangeFilterEvents={onChangeFilterEventsGuest}/>
              <div className="event-list-container">
                  {events.items.length > 0 ?
                      <>
                          {renderEvents.map((item,pos)=> <div key={pos} className={"evzt-row"}>{item}</div>)}
                          {!isLoad && events.pagination.hasOwnProperty("next") && events.pagination.next !== '' ?
                              <>
                                  <div className="load-more">
                                      <button className="evzt-btn btn-white" onClick={(ev) => onClickLoadMoreEventsGuest(ev,paramsGet,events.pagination.next,dispatch)}>{t('loadMore')}...</button>
                                  </div>
                              </>
                              : ""}
                          {
                              !isLoad && events.items.length > NBR_EVENT_PER_PAGE ?
                                  <div className="evzt-text-center reset-ct">
                                      <IconButton sx={{ color: green[500] }} onClick={onResetList}>
                                          <ResetIcon/>
                                      </IconButton>
                                  </div>
                                  : ""
                          }
                      </>
                      :
                      <>
                          { !isLoad ?
                              <>
                                  <div className={"block-large-notification"}>
                                      <div>
                                          {t('noEventInvited')}
                                      </div>
                                  </div>
                              </>
                              :""}
                      </>
                  }
              </div>
              <BrowserView>
                { isLoad ? <LoadingItemsHorizontal count={NBR_EVENT_PER_PAGE} /> : ""}
            </BrowserView>
            <MobileView>
                { isLoad ? <LoadingItemsHorizontal count={1} /> : ""}
            </MobileView>
          </>
      )
}

export default EventsGuestBlock