import React, {useState} from "react";
import {useForm} from "react-hook-form";
import ErrorMessageEvztField from "../ErrorMessageEvztField";
import {onSubmitFormGuest} from "../../EventHandler/OnSubmitEvent";
import {Alert} from "@mui/material";
import SpinerLoading from "../SpinerLoading";
import {useDispatch} from "react-redux";
import {resetContacts} from "../../Store/ContactsReducer";

/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 03/02/2023
 * Time: 12:52
 */
const FormGuest = ({event,onSuccessAdd}) => {
    const [serverErrorMessage,setServerErrorMessage] = useState(false);
    const {register, formState:{errors,isSubmitting},handleSubmit} = useForm({});
    const dispatch = useDispatch()
    const onSubmittedFeedBack = data => {
        if(typeof data.err !=="undefined"){
            setServerErrorMessage(data.err)
        }else {
            if(typeof data.success.event_invitations !== "undefined"){
                const lastIndex = data.success.event_invitations.length - 1
                const {user}    = data.success.event_invitations[lastIndex]
                dispatch(resetContacts())
                onSuccessAdd(user)
            }
        }
    }
    const validationRules = {
        "required" : "This is required field"
    }
    return (
        <>
            <form id={"formSignup"} onSubmit={handleSubmit(d => {
                onSubmitFormGuest(d,event, onSubmittedFeedBack).then(r => setServerErrorMessage(false))
            })}>
                {serverErrorMessage && <Alert onClick={ () => setServerErrorMessage(false)} severity={"error"}>{serverErrorMessage}</Alert>}

                <div className="evzt-form-group">
                    <input type="text" className="evzt-input-control" name={"firstName"} placeholder={"First name"} {...register("first_name",validationRules)}/>
                    <ErrorMessageEvztField errors={errors} fieldName={"first_name"}/>
                </div>
                <div className="evzt-form-group">
                    <input type="text" className="evzt-input-control" name={"lastName"} placeholder={"Last name"} {...register("last_name",validationRules)}/>
                    <ErrorMessageEvztField errors={errors} fieldName={"last_name"}/>
                </div>
                <div className="evzt-form-group">
                    <input type="text" className="evzt-input-control" name={"email"} placeholder={"Email"} {...register("email",validationRules)}/>
                    <ErrorMessageEvztField errors={errors} fieldName={"email"}/>
                </div>
                <button type={"submit"}  className="evzt-btn btn-primary btn-submit submit-load" disabled={isSubmitting}>Save {isSubmitting && <SpinerLoading/>}</button>
            </form>
        </>
    )
}

export default FormGuest