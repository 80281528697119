/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 22/06/2021
 * Time: 11:46
 */
import React from 'react';
import Authentication from "./Authentication";
import {Link} from "react-router-dom";
import FormInscription from "../../Blocks/Form/FormInscription";
import {connect} from "react-redux";
import {addToken, cleanMessageErr, setMessageErr, updateInformation} from "../../Store/UserReducer";
import {withCookies} from "react-cookie";
import {withTranslation} from "react-i18next";
import TransparentLogo from "../../Blocks/TransparentLogo";

class Inscription extends React.Component{
    render() {
        const {  t } = this.props;
        return (
            <>
                <div className="right-side">
                    <p>{t('alreadyAnAccount')}?<Link to={'/login'}>Login</Link></p>
                </div>
                <div className='content-auth'>
                    <div className={'content-auth-form'}>
                        <Link to={"/"}><TransparentLogo /></Link>
                        <FormInscription prop={this.props}/>
                    </div>
                </div>
            </>
        )
    }
}

export const InscriptionStore = connect(
    (state) =>({
        user : state.user,
        err  : state.err
    }) ,
    (dispatch) => ({
        onSubmitLogin : user  => dispatch(updateInformation(user)),
        setToken      : token => dispatch(addToken(token)),
        formFeedBack  : err => dispatch(setMessageErr(err)),
        cleanMessageErr  : err => dispatch(cleanMessageErr(err))
    })
)(Inscription)

export default Authentication(withTranslation()(withCookies(InscriptionStore)))