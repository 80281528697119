import Header from "../Blocks/Header";

const PictureViewer = () => {

    return (
        <>
            <Header />
        </>
    )

}

export default PictureViewer;