/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 22/06/2021
 * Time: 11:47
 */
import React, {Component} from 'react';
import Header from "../../Blocks/Header";
import ProfileBlock from "../../Blocks/ProfileBlock";
import ContactsListBlock from "../../Blocks/ContactsListBlock";

import SimpleReactValidator from 'simple-react-validator';

import FormEvent from "../../Blocks/FormEvent";

class Edit extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator();

        this.state = {
            name : "",
            date : "",
            configuration : {
                emailSender : ""
            }
        }
    }
    /**
     * {Event} : ev
     */
    onChangeInformation = (ev) =>{
        const targetEl = ev.target ;
        const currentState= this.state;
        if(typeof currentState[targetEl.getAttribute("name")] !== "undefined"){
            currentState[targetEl.getAttribute("name")] = targetEl.value
        }
        this.setState(currentState);
        console.log(currentState)
    }

    /**
     * {Event} : ev
     */
    onChangeConfiguration = (ev) =>{
        const targetEl = ev.target ;
        const currentStateConfiguration = this.state.configuration;
        if(typeof currentStateConfiguration[targetEl.getAttribute("name")] !== "undefined"){
            currentStateConfiguration[targetEl.getAttribute("name")] = targetEl.value
        }
        this.setState({
            configuration : currentStateConfiguration
        });
        console.log(currentStateConfiguration)
    }

    render() {
        const state = this.state;

        return (
            <>
                <Header/>
                <div className="evzt-container app-content">
                    <div className={"app-left-side"}>
                        <ProfileBlock/>
                        <ContactsListBlock/>
                    </div>

                    <div className="principal-content">
                        <div className={"event-block"}>
                            <h2>New event</h2>
                            <div className="event-form-content">
                                <FormEvent state={state}/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Edit;