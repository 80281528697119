/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 04/07/2021
 * Time: 11:49
 */

import React, {Component} from 'react';

class LoadingItemsHorizontal extends Component {
    render() {
        console.log(this.props)
        let loading = [];
        for (let i=0;i<this.props.count ; i++){
            loading.push(
                <div key={i} className="horizontal-loading">
                    <div className={"horizontal-loading-content"}>
                        <span className={"horizontal-loading-image"}></span>
                        <div className="horizontal-loading-line">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className={"evzt-row"}>
                {loading}
            </div>
        );
    }
}

export default LoadingItemsHorizontal;