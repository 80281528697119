import EventsGuestBlock from "../../Blocks/EventsGuestBlock"
import Header from "../../Blocks/Header"
import { MobileOnlyView } from "react-device-detect"
import PrivateMobileFooter from "../../Blocks/Mobiles/PrivateMobileFooter"

const MyEventsAttended = () =>{
    return (
        <>
            <Header />
            <div className="evzt-container app-content">
                <div className="app-left-side">
                    <EventsGuestBlock />
                    <MobileOnlyView>
                        <PrivateMobileFooter />
                   </MobileOnlyView>
                </div>
            </div>
        </>
    )
}

export default MyEventsAttended

