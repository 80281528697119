import {Component} from "react";
import {List, Collapse, ListItemText, ListItemIcon, ListItemButton } from '@mui/material';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

import Logout from "../Pages/Authentication/Logout";
import { Divider } from "@mui/material";
class MenuLeftNavigation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openManageEvent : false
        }
    }

    render() {
        const state = this.state;
        const currentProps = this.props;
        const {user,t} = currentProps
        return (
            <>
            <List>
                    {/*<ListItemButton onClick={ () => {}  }>
                        <ListItemIcon>
                            <BusinessCenterIcon />
                        </ListItemIcon>
                        <ListItemText primary="Web Designer"  />
                    </ListItemButton>*/}
                    {/*<ListItemButton onClick={ () => {}  }>
                        <ListItemIcon>
                            <FmdGoodIcon />
                        </ListItemIcon>
                        <ListItem sx={{ pl: 0 }} component={Link} href="/confirm">
                            <ListItemText primary="Adresse" />
                        </ListItem>
                    </ListItemButton>*/}
                    {user.auth !== "USER_GUEST" &&
                        <>
                            <ListItemButton onClick={ () => this.setState({openManageEvent:!state.openManageEvent})  }>
                                <ListItemIcon>
                                    <SettingsApplicationsIcon />
                                </ListItemIcon>
                                <ListItemText primary={t('manageEvents')} />
                                {state.openManageEvent ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Collapse in={state.openManageEvent} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItemButton component="a" sx={{ pl: 4 }} onClick={(ev) => currentProps.redirectPage("/new-event")}>
                                        <ListItemIcon>
                                            <PlaylistAddIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={t('newEvent')} />
                                    </ListItemButton>
                                    {/*<ListItemButton sx={{ pl: 4 }} onClick={(ev) => currentProps.redirectPage("/")}>
                                        <ListItemIcon>
                                            <ListAltIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={t('newEvent')} />
                                    </ListItemButton>*/}
                                </List>
                            </Collapse>
                        </>
                    }

                    <Divider></Divider>
                    <ListItemButton>
                        <Logout classList={'logout'}/>
                    </ListItemButton>
                </List>
            </>
        )
    }
}

export default MenuLeftNavigation