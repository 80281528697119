import PublicFooter from "../Blocks/PublicFooter";
import PublicHeader from "../Blocks/PublicHeader";
import firstImage from "../assets/image/beautiful-bride-with-her-husband-park.jpg";
import secondImage from "../assets/image/elderly-people-celebrating-their-birthday.jpg";
import thirdImage from "../assets/image/people-having-fun-block-party.jpg";
import etude from "../assets/image/fin-d-etude.jpg";
import evenment from "../assets/image/evenement.jpg";
import wedding_part from "../assets/image/wedding-prt.jpg"; 
import restauration_décoration from "../assets/image/restoration.jpg";
import marriage from "../assets/image/mariage.jpg";
import wedding_organisation from "../assets/image/wedding-organisation.jpg";
import charite from "../assets/image/people-preparing-charity.jpg";
import exposition from "../assets/image/exposition.jpg";
import conference from "../assets/image/conference.jpg";
import birthday from "../assets/image/birthday.jpg";
import corporation from "../assets/image/corporation.jpg";
import { useTranslation } from "react-i18next";

const Service = () => {
    const {t} = useTranslation();
    return (
        <>
            <PublicHeader />
            <section id={"privacyBlock"}>
                <div className="privacy-content">
                    <div className="privancy-content evzt-service">
                        <h1>{t("ourService")}</h1>
                    </div>
                    <div className="privancy-content-service evzt-wedding mt-4 mb-2">
                        <div className="evzt-wedding-image">
                            <div className="service-content-evzt">
                                <img src={marriage} alt="evzt-wedding"/>
                            </div> 
                            <div className="service-content-evzt">
                                <img src={wedding_part} alt="evzt-wedding"/>
                            </div>
                            <div className="service-content-evzt">
                                <img src={wedding_organisation} alt="evzt-wedding"/>
                            </div>
                        </div>
                        <div className="evzt-service-text">
                            <div className="service-event"><h2>{t('ServiceItems.Wedding')}</h2></div>
                            <p className="mb-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quibusdam laudantium voluptates quidem veniam libero accusantium at iusto. Neque eaque dolorum necessitatibus sed corrupti nulla nesciunt eius quia quo libero</p>
                            <p className="mb-2 mt-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quibusdam laudantium voluptates quidem veniam libero accusantium at iusto. Neque eaque dolorum necessitatibus sed corrupti nulla nesciunt eius quia quo libero. Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quibusdam laudantium voluptates quidem veniam libero accusantium at iusto. Neque eaque dolorum necessitatibus sed corrupti nulla nesciunt eius quia quo libero?</p>
                        </div>
                    </div>
                    <div className="privancy-content-service evzt-wedding mt-4 mb-2">
                        <div className="evzt-wedding-image">
                            <div className="service-content-evzt">
                                <img src={birthday} alt="evzt-wedding"/>
                            </div> 
                            <div className="service-content-evzt">
                                <img src={wedding_part} alt="evzt-wedding"/>
                            </div>
                            <div className="service-content-evzt">
                                <img src={wedding_organisation} alt="evzt-wedding"/>
                            </div>
                        </div>
                        <div className="evzt-service-text">
                            <div className="service-event"><h2>{t('ServiceItems.Birthday')}</h2></div>
                            <p className="mb-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quibusdam laudantium voluptates quidem veniam libero accusantium at iusto. Neque eaque dolorum necessitatibus sed corrupti nulla nesciunt eius quia quo libero</p>
                            <p className="mb-2 mt-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quibusdam laudantium voluptates quidem veniam libero accusantium at iusto. Neque eaque dolorum necessitatibus sed corrupti nulla nesciunt eius quia quo libero. Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quibusdam laudantium voluptates quidem veniam libero accusantium at iusto. Neque eaque dolorum necessitatibus sed corrupti nulla nesciunt eius quia quo libero?</p>
                        </div>
                    </div>
                    <div className="privancy-content-service evzt-wedding mt-4 mb-2">
                        <div className="evzt-wedding-image">
                            <div className="service-content-evzt">
                                <img src={restauration_décoration } alt="evzt-wedding"/>
                            </div> 
                            <div className="service-content-evzt">
                                <img src={exposition} alt="evzt-wedding"/>
                            </div>
                            <div className="service-content-evzt">
                                <img src={corporation} alt="evzt-wedding"/>
                            </div>
                        </div>
                        <div className="evzt-service-text">
                            <div className="service-event"><h2>{t('ServiceItems.RestaurationDecoration')}</h2></div>
                            <p className="mb-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quibusdam laudantium voluptates quidem veniam libero accusantium at iusto. Neque eaque dolorum necessitatibus sed corrupti nulla nesciunt eius quia quo libero</p>
                            <p className="mb-2 mt-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quibusdam laudantium voluptates quidem veniam libero accusantium at iusto. Neque eaque dolorum necessitatibus sed corrupti nulla nesciunt eius quia quo libero. Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quibusdam laudantium voluptates quidem veniam libero accusantium at iusto. Neque eaque dolorum necessitatibus sed corrupti nulla nesciunt eius quia quo libero?</p>
                        </div>
                    </div>
                    <div className="privancy-content-service evzt-wedding mt-4 mb-2">
                        <div className="evzt-wedding-image">
                            <div className="service-content-evzt">
                                <img src={etude} alt="evzt-wedding"/>
                            </div> 
                            <div className="service-content-evzt">
                                <img src={charite} alt="evzt-wedding"/>
                            </div>
                            <div className="service-content-evzt">
                                <img src={exposition} alt="evzt-wedding"/>
                            </div>
                        </div>
                        <div className="evzt-service-text">
                            <div className="service-event"><h2>{t('ServiceItems.Findetude')}</h2></div>
                            <p className="mb-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quibusdam laudantium voluptates quidem veniam libero accusantium at iusto. Neque eaque dolorum necessitatibus sed corrupti nulla nesciunt eius quia quo libero</p>
                            <p className="mb-2 mt-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quibusdam laudantium voluptates quidem veniam libero accusantium at iusto. Neque eaque dolorum necessitatibus sed corrupti nulla nesciunt eius quia quo libero. Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quibusdam laudantium voluptates quidem veniam libero accusantium at iusto. Neque eaque dolorum necessitatibus sed corrupti nulla nesciunt eius quia quo libero?</p>
                        </div>
                    </div>
                    <div className="privancy-content-service evzt-wedding mt-4 mb-2">
                        <div className="evzt-wedding-image">
                            <div className="service-content-evzt">
                                <img src={conference} alt="evzt-wedding"/>
                            </div> 
                            <div className="service-content-evzt">
                                <img src={wedding_part} alt="evzt-wedding"/>
                            </div>
                            <div className="service-content-evzt">
                                <img src={wedding_organisation} alt="evzt-wedding"/>
                            </div>
                        </div>
                        <div className="evzt-service-text">
                            <div className="service-event"><h2>{t('ServiceItems.ConferenceEvent')}</h2></div>
                            <p className="mb-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quibusdam laudantium voluptates quidem veniam libero accusantium at iusto. Neque eaque dolorum necessitatibus sed corrupti nulla nesciunt eius quia quo libero</p>
                            <p className="mb-2 mt-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quibusdam laudantium voluptates quidem veniam libero accusantium at iusto. Neque eaque dolorum necessitatibus sed corrupti nulla nesciunt eius quia quo libero. Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quibusdam laudantium voluptates quidem veniam libero accusantium at iusto. Neque eaque dolorum necessitatibus sed corrupti nulla nesciunt eius quia quo libero?</p>
                        </div>
                    </div>
                    <div className="privancy-content-service evzt-wedding mt-4 mb-2">
                        <div className="evzt-wedding-image">
                            <div className="service-content-evzt">
                                <img src={charite} alt="evzt-wedding"/>
                            </div> 
                            <div className="service-content-evzt">
                                <img src={thirdImage} alt="evzt-wedding"/>
                            </div>
                            <div className="service-content-evzt">
                                <img src={evenment} alt="evzt-wedding"/>
                            </div>
                        </div>
                        <div className="evzt-service-text">
                            <div className="service-event"><h2>{t('ServiceItems.CharityEvent')}</h2></div>
                            <p className="mb-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quibusdam laudantium voluptates quidem veniam libero accusantium at iusto. Neque eaque dolorum necessitatibus sed corrupti nulla nesciunt eius quia quo libero</p>
                            <p className="mb-2 mt-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quibusdam laudantium voluptates quidem veniam libero accusantium at iusto. Neque eaque dolorum necessitatibus sed corrupti nulla nesciunt eius quia quo libero. Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quibusdam laudantium voluptates quidem veniam libero accusantium at iusto. Neque eaque dolorum necessitatibus sed corrupti nulla nesciunt eius quia quo libero?</p>
                        </div>
                    </div>
                    <div className="privancy-content-service evzt-wedding mt-4 mb-2">
                        <div className="evzt-wedding-image">
                            <div className="service-content-evzt">
                                <img src={corporation} alt="evzt-wedding"/>
                            </div> 
                            <div className="service-content-evzt">
                                <img src={exposition} alt="evzt-wedding"/>
                            </div>
                            <div className="service-content-evzt">
                                <img src={conference} alt="evzt-wedding"/>
                            </div>
                        </div>
                        <div className="evzt-service-text">
                            <div className="service-event"><h2>{t('ServiceItems.CorporateEvent')}</h2></div>
                            <p className="mb-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quibusdam laudantium voluptates quidem veniam libero accusantium at iusto. Neque eaque dolorum necessitatibus sed corrupti nulla nesciunt eius quia quo libero</p>
                            <p className="mb-2 mt-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quibusdam laudantium voluptates quidem veniam libero accusantium at iusto. Neque eaque dolorum necessitatibus sed corrupti nulla nesciunt eius quia quo libero. Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quibusdam laudantium voluptates quidem veniam libero accusantium at iusto. Neque eaque dolorum necessitatibus sed corrupti nulla nesciunt eius quia quo libero?</p>
                        </div>
                    </div>
                    <div className="privancy-content-service evzt-wedding mt-4 mb-2">
                        <div className="evzt-wedding-image">
                            <div className="service-content-evzt">
                                <img src={evenment} alt="evzt-wedding"/>
                            </div> 
                            <div className="service-content-evzt">
                                <img src={wedding_part} alt="evzt-wedding"/>
                            </div>
                            <div className="service-content-evzt">
                                <img src={corporation} alt="evzt-wedding"/>
                            </div>
                        </div>
                        <div className="evzt-service-text">
                            <div className="service-event"><h2>{t('ServiceItems.EventPhotography')}</h2></div>
                            <p className="mb-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quibusdam laudantium voluptates quidem veniam libero accusantium at iusto. Neque eaque dolorum necessitatibus sed corrupti nulla nesciunt eius quia quo libero</p>
                            <p className="mb-2 mt-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quibusdam laudantium voluptates quidem veniam libero accusantium at iusto. Neque eaque dolorum necessitatibus sed corrupti nulla nesciunt eius quia quo libero. Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quibusdam laudantium voluptates quidem veniam libero accusantium at iusto. Neque eaque dolorum necessitatibus sed corrupti nulla nesciunt eius quia quo libero?</p>
                        </div>
                    </div>
                    <div className="privancy-content-service evzt-wedding mt-4 mb-2">
                        <div className="evzt-wedding-image">
                            <div className="service-content-evzt">
                                <img src={exposition} alt="evzt-wedding"/>
                            </div> 
                            <div className="service-content-evzt">
                                <img src={exposition} alt="evzt-wedding"/>
                            </div>
                            <div className="service-content-evzt">
                                <img src={wedding_organisation} alt="evzt-wedding"/>
                            </div>
                        </div>
                        <div className="evzt-service-text">
                            <div className="service-event"><h2>{t('ServiceItems.Exposition')}</h2></div>
                            <p className="mb-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quibusdam laudantium voluptates quidem veniam libero accusantium at iusto. Neque eaque dolorum necessitatibus sed corrupti nulla nesciunt eius quia quo libero</p>
                            <p className="mb-2 mt-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quibusdam laudantium voluptates quidem veniam libero accusantium at iusto. Neque eaque dolorum necessitatibus sed corrupti nulla nesciunt eius quia quo libero. Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quibusdam laudantium voluptates quidem veniam libero accusantium at iusto. Neque eaque dolorum necessitatibus sed corrupti nulla nesciunt eius quia quo libero?</p>
                        </div>
                    </div>
                    <div className="privancy-content-service evzt-wedding mt-4 mb-2">
                        <div className="evzt-wedding-image">
                            <div className="service-content-evzt">
                                <img src={thirdImage} alt="evzt-wedding"/>
                            </div> 
                            <div className="service-content-evzt">
                                <img src={evenment} alt="evzt-wedding"/>
                            </div>
                            <div className="service-content-evzt">
                                <img src={wedding_organisation} alt="evzt-wedding"/>
                            </div>
                        </div>
                        <div className="evzt-service-text">
                            <div className="service-event"><h2>{t('ServiceItems.FamilyHoliday')}</h2></div>
                            <p className="mb-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quibusdam laudantium voluptates quidem veniam libero accusantium at iusto. Neque eaque dolorum necessitatibus sed corrupti nulla nesciunt eius quia quo libero</p>
                            <p className="mb-2 mt-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quibusdam laudantium voluptates quidem veniam libero accusantium at iusto. Neque eaque dolorum necessitatibus sed corrupti nulla nesciunt eius quia quo libero. Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum quibusdam laudantium voluptates quidem veniam libero accusantium at iusto. Neque eaque dolorum necessitatibus sed corrupti nulla nesciunt eius quia quo libero?</p>
                        </div>
                    </div>
                    <div className="content-service-animation">
                        <div className="content-service-animation-imag">
                            <img src={firstImage} alt="evzt-wedding"/> 
                        </div>
                        <div className="content-service-animation-imag">
                            <img src={secondImage} alt="evzt-wedding"/> 
                        </div>
                        <div className="content-service-animation-imag">
                            <img src={thirdImage} alt="evzt-wedding"/> 
                        </div>
                        <div className="content-service-animation-imag">
                            <img src={etude} alt="evzt-wedding"/> 
                        </div>
                        <div className="content-service-animation-imag">
                            <img src={evenment} alt="evzt-wedding"/> 
                        </div>
                        <div className="content-service-animation-imag">
                            <img src={wedding_part} alt="evzt-wedding"/> 
                        </div>
                        <div className="content-service-animation-imag">
                            <img src={restauration_décoration} alt="evzt-wedding"/> 
                        </div>
                        <div className="content-service-animation-imag">
                            <img src={marriage} alt="evzt-wedding"/> 
                        </div>
                        <div className="content-service-animation-imag">
                            <img src={wedding_organisation} alt="evzt-wedding"/> 
                        </div>
                        <div className="content-service-animation-imag">
                            <img src={charite} alt="evzt-wedding"/> 
                        </div>
                        <div className="content-service-animation-imag">
                            <img src={exposition} alt="evzt-wedding"/> 
                        </div>
                        <div className="content-service-animation-imag">
                            <img src={conference} alt="evzt-wedding"/> 
                        </div>
                        <div className="content-service-animation-imag">
                            <img src={birthday} alt="evzt-wedding"/> 
                        </div>
                        <div className="content-service-animation-imag">
                            <img src={corporation} alt="evzt-wedding"/> 
                        </div>
                    </div>
                </div>
            </section>
            
            <PublicFooter />
        </>
    )
}
export default Service