/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 22/06/2021
 * Time: 11:54
 */
import { Component } from "react";
import Header from "../Blocks/Header";
import { connect } from "react-redux";
import { cleanMessageErr, setMessageErr } from "../Store/UserReducer";
import { withCookies } from "react-cookie";
import { USER_ACCESS } from "../Utils/Access";
import PrivateFooter from "../Blocks/PrivateFooter";
import RandomImagesEventBlock from "../Blocks/RandomImagesEventBlock";
import { BrowserView, MobileView } from "react-device-detect";
import PrivateMobileFooter from "../Blocks/Mobiles/PrivateMobileFooter";

class MySpace extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: window.innerWidth <= 767,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setState({ isScreenWidthSufficient: window.innerWidth <= 767 });
  };
  render() {
    const { user } = this.props;
    const { isMobile } = this.state;

    let blockMenuLefts = [];
    let blockContainers = [];
    if (user && user.hasOwnProperty("auth") && user.auth !== "") {
      blockMenuLefts = USER_ACCESS[user.auth].blockMenuLeft;
      blockContainers = USER_ACCESS[user.auth].blockContainers;
    }
    return (
      <>
        <Header />
        <div className="evzt-container app-content">
          <div className={"app-left-side"}>
            {blockMenuLefts.map((item, pos) => (
              <div key={"menuLeft-" + pos}>{item}</div>
            ))}
            {/* <PrivateFooter /> */}
          </div>

          <div className="principal-content">
            {/*render content of each page*/}
            {isMobile ? (
              <RandomImagesEventBlock />
            ) : (
              blockContainers.map((item, pos) => (
                <div key={"container-" + pos}>{item}</div>
              ))
            )}
          </div>
          <BrowserView>
            <PrivateFooter />
          </BrowserView>
          <MobileView>
            <PrivateMobileFooter />
          </MobileView>
        </div>
      </>
    );
  }
}

export const MySpaceStore = connect(
  (state) => ({
    user: state.user,
    err: state.err,
  }),
  (dispatch) => ({
    formFeedBack: (err) => dispatch(setMessageErr(err)),
    cleanMessageErr: (err) => dispatch(cleanMessageErr(err)),
  })
)(MySpace);

export default withCookies(MySpaceStore);
