import { createTheme } from '@mui/material/styles';

const EvztTheme = createTheme({
    palette: {
        primary: {
            main: '#3aa65d',
        },
        secondary: {
            main: '#3e407e'
        },
        success: {
            main: '#c0e4af'
        },
        error: {
            main: '#c70038'
        }
    },
    status: {
        default: '#9899b0',
        danger: '#c70038',
        blue: '#3769b3',
        White: '#ffffff',
        darkWhite: '#f5f4f0',
        pink:'#ff8faf',
        green: '#359754',
        lightGreen: '#c0e4af',
        orange: '#ff5733'
    }
});

export default EvztTheme;