import {getContacts, getEvents, getEventsGuest} from "../Utils/Common";
import {putToList, setContactLoading} from "../Store/ContactsReducer";
import {putToList as putToListEvents, setEventLoading} from "../Store/EventsReducer";
import axios from "axios";
import {API_USER_UPLOAD_PROFILE, SERVER_BASE_URL} from "../Utils/Defines";
import {updateAvatar} from "../Store/UserReducer";
import {setEventGuestLoading,putToList as putToListEventsGuest} from "../Store/EventsUserAttendedReducer";

/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 09/07/2021
 * Time: 15:04
 */
/**
 *
 * @param ev
 * @param urlParams
 * @param next
 * @param dispatch callback redux
 */
export const onClickLoadMoreContact = (ev,urlParams,next,dispatch) => {
    ev.preventDefault()
    urlParams.next = next;
    dispatch(setContactLoading(true))
    getContacts(urlParams).then((data)=>{
        if(data){
            dispatch(setContactLoading(false))
            dispatch(putToList(data))
        }
    }).catch((error) => {
        console.log(error);
        dispatch(setContactLoading(false));
    }).finally(() => dispatch(setContactLoading(false)))
}
/**
 *
 * @param ev
 * @param urlParams
 * @param next
 * @param dispatch
 */
export const onClickLoadMoreGallery = (ev,urlParams,next,dispatch) => {
    ev.preventDefault()
    urlParams.next = next;
    console.log(urlParams)
}

/**
 *
 * @param ev
 * @param urlParams
 * @param next
 * @param dispatch callback redux
 */
 export const onClickLoadMoreEvents = (ev,urlParams,next,dispatch) => {
    ev.preventDefault()
    urlParams.next = next;
    dispatch(setEventLoading(true))
    getEvents(urlParams).then((data)=>{
        if(data){
            dispatch(setEventLoading(false))
            dispatch(putToListEvents(data))
        }
    }).catch((error) => {
        console.log(error);
        dispatch(setEventLoading(false));
    }).finally(() => dispatch(setEventLoading(false)))
}

/**
 *
 * @param ev
 * @param urlParams
 * @param next
 * @param dispatch callback redux
 */
 export const onClickLoadMoreEventsGuest = (ev,urlParams,next,dispatch) => {
    ev.preventDefault()
    urlParams.next = next;
    dispatch(setEventGuestLoading(true))
    getEventsGuest(urlParams).then((data)=>{
        if(data){
            dispatch(setEventGuestLoading(false))
            dispatch(putToListEventsGuest(data))
        }
    }).catch((error) => {
        console.log(error);
        dispatch(setEventGuestLoading(false));
    }).finally(() => dispatch(setEventGuestLoading(false)))
}

/**
 *
 * @param content
 * @param userId
 * @param dispatch
 * @param {Function} setErrorMessage
 */
export const onUpload = (content,userId,dispatch,setErrorMessage) => {
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append(
        "profil_name",
        content
    );
    formData.append("user_id",userId)
    return axios.post(API_USER_UPLOAD_PROFILE,formData)
        .then(function (response) {
            const {data} = response
            console.log(data.success.url)
            dispatch(updateAvatar({"avatar": SERVER_BASE_URL+data.success.url}));
            return data
        })
        .catch(function (error) {
            if(typeof setErrorMessage === "function")
                setErrorMessage(error.response)
            return error.response
        });
}