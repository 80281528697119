import { Icon } from "@mui/material";
import Header from "../../Blocks/Header";

const Confirm = () => <>
    <Header />
    <div className="evzt-container">
        <div className="evzt-text-center mtb50">
            <h1>Lorem ipsum invitation title</h1>
            <p>Efficiently reinvent multimedia based action items for enterprise-wide web services.</p>
        </div>
        <div className="tuto-area evzt-row mtb50">
            <div className="top-left-content evzt-po-relative">
                <div className="tuto">
                    <img src="https://devevzt.lead-code-group.com/img/tutos-upload-avatar.gif" alt="Tutorial to upload a user avatar" />
                </div>
                <div className="bulb confirm-abs confirm-index">
                    <svg xmlns="http://www.w3.org/2000/svg" width="105" height="106" viewBox="0 0 105 106">
                        <circle cx="52.5" cy="52.5" r="52.5" fill="#ffc300" data-name="Ellipse 11" transform="translate(0 1)" />
                        <circle cx="12.5" cy="12.5" r="12.5" fill="#3769b3" data-name="Ellipse 12" transform="translate(10)" />
                    </svg>
                </div>
                <div className="discs confirm-abs confirm-index">
                    <svg xmlns="http://www.w3.org/2000/svg" width="86" height="86">
                        <path fill="none" d="M0 0h86v86H0Z" data-name="Tracé 11" />
                        <path fill="#d5d4d4" d="M21.5 46.583a3.583 3.583 0 1 0 3.583 3.583 3.594 3.594 0 0 0-3.583-3.583Zm0 14.333a3.583 3.583 0 1 0 3.583 3.583 3.594 3.594 0 0 0-3.583-3.583Zm0-28.667a3.583 3.583 0 1 0 3.583 3.583A3.594 3.594 0 0 0 21.5 32.25Zm-10.75 1.792a1.792 1.792 0 1 0 1.792 1.792 1.774 1.774 0 0 0-1.792-1.792ZM21.5 17.916a3.583 3.583 0 1 0 3.583 3.583 3.594 3.594 0 0 0-3.583-3.583Zm53.75 19.708a1.792 1.792 0 1 0-1.792-1.791 1.774 1.774 0 0 0 1.792 1.792ZM50.166 25.083a3.583 3.583 0 1 0-3.583-3.583 3.594 3.594 0 0 0 3.583 3.583Zm0-12.542a1.792 1.792 0 1 0-1.792-1.792 1.774 1.774 0 0 0 1.792 1.792ZM10.75 48.375a1.792 1.792 0 1 0 1.792 1.792 1.774 1.774 0 0 0-1.792-1.792Zm25.083 25.083a1.792 1.792 0 1 0 1.792 1.792 1.774 1.774 0 0 0-1.792-1.792Zm0-60.917a1.792 1.792 0 1 0-1.792-1.792 1.774 1.774 0 0 0 1.792 1.792Zm0 12.542A3.583 3.583 0 1 0 32.25 21.5a3.594 3.594 0 0 0 3.583 3.583Zm0 19.708a5.375 5.375 0 1 0 5.375 5.375 5.368 5.368 0 0 0-5.375-5.375ZM64.5 46.583a3.583 3.583 0 1 0 3.583 3.583 3.594 3.594 0 0 0-3.583-3.583Zm0 14.333a3.583 3.583 0 1 0 3.583 3.583 3.594 3.594 0 0 0-3.583-3.583Zm0-28.667a3.583 3.583 0 1 0 3.583 3.583A3.594 3.594 0 0 0 64.5 32.25Zm0-14.333a3.583 3.583 0 1 0 3.583 3.583 3.594 3.594 0 0 0-3.583-3.583Zm10.75 30.458a1.792 1.792 0 1 0 1.792 1.792 1.774 1.774 0 0 0-1.792-1.791ZM50.166 60.916a3.583 3.583 0 1 0 3.583 3.583 3.594 3.594 0 0 0-3.583-3.583Zm0 12.542a1.792 1.792 0 1 0 1.792 1.792 1.774 1.774 0 0 0-1.792-1.792Zm-14.333-43a5.375 5.375 0 1 0 5.375 5.375 5.368 5.368 0 0 0-5.375-5.375Zm0 30.458a3.583 3.583 0 1 0 3.583 3.583 3.594 3.594 0 0 0-3.583-3.583Zm14.333-16.125a5.375 5.375 0 1 0 5.375 5.375 5.368 5.368 0 0 0-5.375-5.375Zm0-14.333a5.375 5.375 0 1 0 5.375 5.375 5.368 5.368 0 0 0-5.375-5.375Z" data-name="Tracé 12" />
                    </svg>
                </div>
            </div>
            <div className="top-right-content evzt-po-relative">
                <div className="tuto-desc">
                    <h2>Pour mieux vous identifier</h2>
                    <ul>
                        <li>Veuillez ajouter un photo de profil sur lequel votre visage est bienapparent</li>
                        <li>Ensuite, delimiter votre visage et centraliser le entre les grilles de zoom</li>
                    </ul>
                </div>
                <div className="gray-cross confirm-abs confirm-index">
                    <svg xmlns="http://www.w3.org/2000/svg" width="66.935" height="66.935" viewBox="0 0 66.935 66.935">
                        <g data-name="Groupe 16">
                            <g data-name="Groupe 14">
                                <path fill="#d5d4d4" d="m56.435 24.249-18.186 10.5 10.5 18.186-6.062 3.5-10.5-18.186L14 48.749l-3.5-6.062 18.187-10.5L18.187 14l6.062-3.5 10.5 18.187 18.186-10.5Z" data-name="Tracé 10" />
                            </g>
                        </g>
                    </svg>
                </div>
                <div className="envelop confirm-abs confirm-index">
                    <svg xmlns="http://www.w3.org/2000/svg" width="200.083" height="182.87" viewBox="0 0 200.083 182.87">
                        <defs>
                            <filter id="a" width="183.166" height="182.87" x="0" y="0" filterUnits="userSpaceOnUse">
                                <feOffset dy="3" />
                                <feGaussianBlur result="blur" stdDeviation="10" />
                                <feFlood flood-opacity=".161" />
                                <feComposite in2="blur" operator="in" />
                                <feComposite in="SourceGraphic" />
                            </filter>
                            <filter id="b" width="85.565" height="84.497" x="76.528" y="21" filterUnits="userSpaceOnUse">
                                <feOffset dy="3" />
                                <feGaussianBlur result="blur-2" stdDeviation="3" />
                                <feFlood flood-opacity=".161" />
                                <feComposite in2="blur-2" operator="in" />
                                <feComposite in="SourceGraphic" />
                            </filter>
                        </defs>
                        <circle cx="52.5" cy="52.5" r="52.5" fill="#3769b3" data-name="Ellipse 13" transform="translate(95.083 40.935)" />
                        <g filter="url(#a)">
                            <path fill="#fff" d="m86.27 27 66.96 64.17-55.89 58.32-66.96-64.17z" data-name="Rectangle 86" />
                        </g>
                        <g filter="url(#b)">
                            <path fill="none" stroke="#e3e3e3" d="M86.08 26.995V81.93s-1 12 8 11 59 0 59 0" data-name="Tracé 9" />
                        </g>
                    </svg>
                </div>
            </div>
        </div>
        <div className="feat-gallery">{/*waiting for export of items ...*/}</div>
        <div className="upload-area evzt-row evzt-po-relative">
            <div className="thumb confirm-index"><img src="https://picsum.photos/id/102/150/150" alt="Gallerie thumb" /></div>
            <div id="upload_content" className="upload-area-content">
                <div className="image">
                    <img src="https://devevzt.lead-code-group.com//img/avatar.jpg" alt="Avatar name" />
                    <button id="upload_avatar" className="btn-upload-image" type="button"
                        onClick={console.log('yep!')}>
                        <Icon sx={{ fontSize: 18 }}>camera_alt</Icon>
                    </button>
                </div>
                <div className="yellow-cross confirm-abs confirm-index">
                    <svg xmlns="http://www.w3.org/2000/svg" width="107.664" height="107.664">
                        <g data-name="Groupe 13">
                            <path fill="none" d="M28.73 107.664.002 28.704 78.962-.024l28.728 78.96z" data-name="Rectangle 90" />
                        </g>
                        <g data-name="Groupe 15">
                            <g data-name="Groupe 14">
                                <path fill="#ffc300" d="m48.757 29.593 7.182 19.74 19.74-7.182 2.394 6.58-19.74 7.182 7.182 19.74-6.58 2.394-7.182-19.74-19.74 7.182-2.394-6.58 19.74-7.182-7.182-19.74Z" data-name="Tracé 10" />
                            </g>
                        </g>
                    </svg>
                </div>
            </div>
        </div>
    </div>
</>
export default Confirm;

