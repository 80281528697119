import PublicHeader from "../../Blocks/PublicHeader";
import PublicFooter from "../../Blocks/PublicFooter";
import {Link} from "react-router-dom";
import { Trans } from 'react-i18next';
function NotFound() {
    return <>
            <PublicHeader/>
                <div class="not-found">
                    <h1 class="not-found-status">404</h1>
                    <p class="not-found-title">
                        <Trans i18nKey="errors.page404.title">
                            Page not found
                        </Trans>
                    </p>
                    <Trans i18nKey="errors.page404.desc">
                            Page not found
                    </Trans>
                    <p className="evzt-text-center link-home-wrapper">
                        <Link to={'/'} className="evzt-btn btn-primary">
                            <Trans i18nKey="errors.page404.btn.backhomepage">
                                Back to the home page
                            </Trans>
                        </Link>
                    </p>
                </div>
            <PublicFooter/>
        </>;
}
export default NotFound;