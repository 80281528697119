/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 08/04/2023
 * Time: 14:42
 */

import { useTranslation } from "react-i18next";
import {LANGUAGES} from "../Utils/Defines";
import {setUserLang} from "../Utils/Common";


export const MenuLang = () => {
    const { t,i18n } = useTranslation();
    const onChangeLang = (e) => {
        const lang_code = e.currentTarget.getAttribute('value');
        i18n.changeLanguage(lang_code).then(r => setUserLang(lang_code));

    };

    return (
        <>
            <p className={"evzt-lang-label"}>{t('langChoice')} : </p>
            <ul className="lang-selector">
                {LANGUAGES.map(({ code, label }) => (
                    <li key={code} className={`lang ${i18n.language === code ? 'active' : ''}`}>
                        <button type="button" value={code} onClick={onChangeLang}>
                            <span className="lang-flag"><img src={require(`../assets/image/flags/${code}.jpg`)} alt="" /></span>
                            <span className="lang-label">{label[i18n.language]}</span>
                        </button>
                    </li>
                ))}
            </ul>
        </>
    );
};