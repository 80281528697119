import InputPassword from "../../Utils/InputPassword";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ErrorMessageEvztField from "../ErrorMessageEvztField";
import {
  onSubmitInscription,
  onSubmitInscriptionSocialNetwork,
} from "../../EventHandler/OnSubmitEvent";
import ErrorFeedBack from "../ErrorFeedBack";
import SpinerLoading from "../SpinerLoading";
import { LoginSocialFacebook, LoginSocialGoogle } from "reactjs-social-login";
import {
  GoogleSingUpButton,
  FacebookSingUpButton,
} from "../../Pages/Authentication/button/SingInButton";
import { useCallback } from "react";

/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 03/02/2023
 * Time: 12:52
 */

const FormInscription = ({ prop }) => {
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm({});
  const [isVisiblePassword, setIsVisiblePassword] = useState(false);
  const feedBackErr = prop.user.err;
  const { cookies } = prop;
  const validationRules = {
    required: "This is required field",
  };
  const { t } = prop;
  const majTokenInCookie = (data) => {
    cookies.set("ac", JSON.stringify(data), { maxAge: 172800 });
  };
  console.log(isSubmitting);

  // const [Provider, setProvider]= useState('');
  // const [Profile, setProfile]= useState(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onLoginStart = useCallback(() => {
    console.log("Login start");
  });
  return (
    <>
      <form
        id={"formSignup"}
        onSubmit={handleSubmit((d) =>
          onSubmitInscription(d, prop, majTokenInCookie)
        )}
      >
        <div className="auth-social-login">
          <div className="auth-social-form-group">
            <LoginSocialGoogle
              isOnlyGetToken={false}
              client_id={process.env.REACT_APP_GOOGLE_APP_ID}
              onLoginStart={onLoginStart}
              onResolve={({ provider, data }) =>
                onSubmitInscriptionSocialNetwork(
                  provider,
                  data,
                  prop,
                  majTokenInCookie
                )
              }
              onReject={(err) => {
                console.log(err);
              }}
              scope={"profile email"}
            >
              <GoogleSingUpButton />
            </LoginSocialGoogle>
          </div>
          <div className="auth-social-form-group">
            <LoginSocialFacebook
              appId={process.env.REACT_APP_FACEBOOK_APP_ID}
              fieldsProfile={
                "id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender"
              }
              onLoginStart={onLoginStart}
              onReject={(err) => {
                console.log(err);
              }}
              onResolve={({ provider, data }) =>
                onSubmitInscriptionSocialNetwork(
                  provider,
                  data,
                  prop,
                  majTokenInCookie
                )
              }
            >
              <FacebookSingUpButton />
            </LoginSocialFacebook>
          </div>
        </div>
        <div>
          <ErrorFeedBack
            message={feedBackErr}
            classList={"evzt-alert error"}
            callBack={prop.cleanMessageErr}
          />
          <div className="auth-input-form-group">
            <input
              type="text"
              name={"firstName"}
              placeholder={t("firstName")}
              {...register("first_name", validationRules)}
            />
            <ErrorMessageEvztField errors={errors} fieldName={"first_name"} />
          </div>
          <div className="auth-input-form-group">
            <input
              type="text"
              name={"lastName"}
              placeholder={t("lastName")}
              {...register("last_name", validationRules)}
            />
            <ErrorMessageEvztField errors={errors} fieldName={"last_name"} />
          </div>
          <div className="auth-input-form-group">
            <input
              type="text"
              name={"email"}
              placeholder={t("email")}
              {...register("email", validationRules)}
            />
            <ErrorMessageEvztField errors={errors} fieldName={"email"} />
          </div>
          <div className="auth-input-form-group">
            <InputPassword
              label={""}
              inputName={"password"}
              placeHoldIt={t("password")}
              isVisiblePassword={isVisiblePassword}
              setIsVisiblePassword={setIsVisiblePassword}
              register={register}
              errors={errors}
              validationRules={validationRules}
            />
          </div>
          <div className="auth-input-form-group">
            <InputPassword
              inputName={"confirmPassword"}
              placeHoldIt={t("passwordConfirm")}
              isVisiblePassword={isVisiblePassword}
              setIsVisiblePassword={setIsVisiblePassword}
              label={""}
              register={register}
              errors={errors}
              validationRules={validationRules}
            />
          </div>
        </div>
        <div className="submiting">
          <button
            type={"submit"}
            disabled={isSubmitting}
            className="btn-submit submit-load"
          >
            {t("signUp")}
            {isSubmitting && <SpinerLoading />}
          </button>
        </div>
      </form>
    </>
  );
};

export default FormInscription;
