import { Gallery } from "react-grid-gallery";
import {useState} from "react";
import {getRelatedImages} from "../Utils/Common";
import {SERVER_BASE_URL} from "../Utils/Defines";
import "react-image-lightbox/style.css";
import Lightbox from "react-image-lightbox";
import {useTranslation} from "react-i18next";
import SimpleLoadingText from "./Loaders/SimpleLoadingText";
import { StyledEngineProvider, CssVarsProvider } from '@mui/joy/styles';
const RandomImagesEventBlock = () => {
    const {t}                = useTranslation();
    const [images, setImages] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [index, setIndex] = useState(-1);

    const currentImage = images[index];
    const nextIndex = (index + 1) % images.length;
    const nextImage = images[nextIndex] || currentImage;
    const prevIndex = (index + images.length - 1) % images.length;
    const prevImage = images[prevIndex] || currentImage;

    const handleClick = (index, item) => setIndex(index);
    const handleClose = () => setIndex(-1);
    const handleMovePrev = () => setIndex(prevIndex);
    const handleMoveNext = () => setIndex(nextIndex);

    if(!images){
        getRelatedImages().then(
            data => {
                setIsLoading(false)
                if(data){
                    const realData = data.map(item => {
                        item.src = SERVER_BASE_URL+item.src;
                        item.isSelected = false;
                        return item;
                    })
                    console.log(realData)
                    setImages(realData)
                }else{
                    setImages([])
                }

            }
        )
    }
    return (
            <>
                <div className="random-images">
                    <div display='flex'>
                        <h2>{t('relatedImages')}</h2>
                    </div>
                    <div className="random-images-gallery">
                        <StyledEngineProvider injectFirst>
                            <CssVarsProvider>{isLoading && <SimpleLoadingText />}</CssVarsProvider>
                        </StyledEngineProvider>
                        {images.length > 0 && <Gallery
                            onClick={handleClick}
                            images={images} />}
                        {!!currentImage && (
                            /* @ts-ignore */
                            <Lightbox
                                mainSrc={currentImage.origin}
                                imageTitle={currentImage.caption}
                                mainSrcThumbnail={currentImage.src}
                                nextSrc={nextImage.origin}
                                nextSrcThumbnail={nextImage.src}
                                prevSrc={prevImage.origin}
                                prevSrcThumbnail={prevImage.origin}
                                onCloseRequest={handleClose}
                                onMovePrevRequest={handleMovePrev}
                                onMoveNextRequest={handleMoveNext}
                            />
                        )}
                    </div>
                </div>
            </>
        )
    }
export default RandomImagesEventBlock;