import { onClickLoadMoreEvents } from "../EventHandler/OnClickEvents";
import { NBR_EVENT_PER_PAGE } from "../Utils/Defines";
import IconButton from "@mui/material/IconButton";
import { green } from "@mui/material/colors";
import ResetIcon from "@mui/icons-material/Refresh";
import LoadingItemsHorizontal from "./LoadingItemsHorizontal";
import ModalConfirmRemoveEvent from "./Modal/ModalConfirmRemoveEvent";
import ModalUploadImageEvent from "./Modal/ModalUploadImageEvent";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { eventsOnLoadSelector, eventsSelector } from "../Store/Selectors";
import { useHistory } from "react-router-dom";
import {
  refactContactToDefaultList,
  removeEventInList,
  setEventInfos,
  setEventLoading,
  setFormEventSaved,
} from "../Store/EventsReducer";
import {
  deleteEventById,
  displayContainError,
  getEvents,
} from "../Utils/Common";
import ItemEvent from "./ItemEvent";
import SearchBlock from "./SearchBlock";
import { onChangeFilterEvents } from "../EventHandler/onChangeInputEvent";
import { useTranslation } from "react-i18next";
import { Alert } from "@mui/material";
import { BrowserView, MobileView } from "react-device-detect";
import LoadingItemsVertical from "./LoadingItemsVertical";

/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 17/03/2023
 * Time: 13:23
 */

const EventsUser = () => {
  const events = useSelector(eventsSelector);
  const isLoad = useSelector(eventsOnLoadSelector);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const paramsGet = {
    next: 1,
    prev: 0,
    perPage: NBR_EVENT_PER_PAGE,
    filters: events.filters,
  };
  const history = useHistory();
  const [openModalRemove, setOpenModalRemove] = React.useState(false);
  const [openModalUpload, setOpenModalUpload] = React.useState(false);
  const [event, setEvent] = React.useState(false);
  const [error, setError] = React.useState(false);
  const redirectPage = (path) => history.push(path);
  const handleClickOpen = (event) => {
    setOpenModalRemove(true);
    setEvent(event);
  };

  const handleNo = () => {
    setOpenModalRemove(false);
  };
  const handleClickOpenGuest = (event) => {
    history.push("/event-guests/" + event.id);
  };
  const handleClickOpenModalUpload = (event) => {
    setOpenModalUpload(true);
    setEvent(event);
  };

  const handleCloseModalUpload = () => {
    setOpenModalUpload(false);
  };

  const onResetList = () => dispatch(refactContactToDefaultList());

  const handleYes = ({ id }) => {
    setError(false);
    deleteEventById(id)
      .then((data) => {
        if (data.success === "EVENT_DELETED") {
          handleNo();
          dispatch(removeEventInList(id));
        }
      })
      .catch((error) => {
        handleNo();
        console.log(error.response);
        setError(error);
      })
      .finally(() => {
        handleNo();
      });
  };
  dispatch(setFormEventSaved(false));
  if (isLoad && events.count === 0) {
    getEvents(paramsGet)
      .then((data) => {
        if (data) {
          dispatch(setEventInfos(data));
          console.log(data);
        }
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        dispatch(setEventLoading(false));
      });
  }
  const total_line = Math.ceil(events.items.length / NBR_EVENT_PER_PAGE);
  let renderEvents = [];
  let nextSlice = 0;
  let endSlice = NBR_EVENT_PER_PAGE;
  for (let i = 0; i < total_line; i++) {
    const newItemsEvents = events.items.slice(nextSlice, endSlice);
    const rows = newItemsEvents.map((event, pos) => (
      <ItemEvent
        event={event}
        key={`event-${pos}`}
        redirectPage={redirectPage}
        onManageGuest={handleClickOpenGuest}
        onManageUpload={handleClickOpenModalUpload}
        onRemove={handleClickOpen}
        isGuest={false}
      />
    ));
    renderEvents.push(rows);
    nextSlice += NBR_EVENT_PER_PAGE;
    endSlice += NBR_EVENT_PER_PAGE;
  }
  return (
    <>
      <h2>{t("myEvents")}</h2>
      <SearchBlock
        dispatch={dispatch}
        events={events}
        onChangeFilterEvents={onChangeFilterEvents}
      />
      <div className="event-list-container">
        {error && (
          <Alert variant="filled" severity="error">
            {displayContainError(error)}
          </Alert>
        )}
        {events.items.length > 0 ? (
          <>
            {renderEvents.map((item, pos) => (
              <div key={pos} className={"evzt-row"}>
                {item}
              </div>
            ))}
            {!isLoad &&
            events.pagination.hasOwnProperty("next") &&
            events.pagination.next !== "" ? (
              <>
                <div className="load-more">
                  <button
                    className="evzt-btn btn-white"
                    onClick={(ev) =>
                      onClickLoadMoreEvents(
                        ev,
                        paramsGet,
                        events.pagination.next,
                        dispatch
                      )
                    }
                  >
                    {t("loadMore")}...
                  </button>
                </div>
              </>
            ) : (
              ""
            )}
            {!isLoad && events.items.length > NBR_EVENT_PER_PAGE ? (
              <div className="evzt-text-center reset-ct">
                <IconButton sx={{ color: green[500] }} onClick={onResetList}>
                  <ResetIcon />
                </IconButton>
              </div>
            ) : (
              ""
            )}
          </>
        ) : (
          <>
            {!isLoad ? (
              <>
                <div className={"block-large-notification"}>
                  <div>{t("noEvent")}</div>
                </div>
              </>
            ) : (
              ""
            )}
          </>
        )}
      </div>
      <BrowserView>
        {isLoad ? <LoadingItemsHorizontal count={NBR_EVENT_PER_PAGE} /> : ""}
      </BrowserView>
      <MobileView>
        {isLoad ? <LoadingItemsVertical count={NBR_EVENT_PER_PAGE} /> : ""}
      </MobileView>
      {events.items.length > 0 ? (
        <>
          <ModalConfirmRemoveEvent
            open={openModalRemove}
            objectToRemove={event}
            handleNo={handleNo}
            handleYes={handleYes}
          />
          <ModalUploadImageEvent
            open={openModalUpload}
            handleClose={handleCloseModalUpload}
            event={event}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
};
export default EventsUser;
