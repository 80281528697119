/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 30/06/2021
 * Time: 15:19
 */

export const userSelector               = ({user}) => user
export const tokenSelector              = ({user}) => user.token
export const contactsSelector           = ({contacts}) => contacts
export const contactsCountSelector      = ({contacts}) => contacts.count
export const contactsOnLoadSelector     = ({contacts}) => contacts.onLoad
export const eventsSelector             = ({events}) => events
export const eventsGuestSelector        = ({eventsUserAttended}) => eventsUserAttended
export const eventsCountSelector        = ({events}) => events.count
export const eventsOnLoadSelector       = ({events}) => events.onLoad
export const eventsGuestOnLoadSelector  = ({eventsUserAttended}) => eventsUserAttended.onLoad

export const validTokenSelector = ({user}) => {
    if(Object.keys(user.token).length > 0){
        return true
    }
    return false
}