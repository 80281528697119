/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 30/06/2021
 * Time: 15:36
 */
import React from "react";
import {USER_ACCESS} from "../Utils/Access";
import {userSelector} from "../Store/Selectors";
export default function ListEventBlock() {
    let blockContainers = []
    const user = userSelector(userSelector)
    if(user && user.hasOwnProperty('auth') && user.auth !==""){
        blockContainers =  USER_ACCESS[user.auth].blockContainers
    }
    return (
        <>
            {blockContainers}
        </>
    )
}
