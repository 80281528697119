/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 22/06/2021
 * Time: 11:47
 */
import React from "react";
import Header from "../../Blocks/Header";
import ProfileBlock from "../../Blocks/ProfileBlock";
import ListEventBlock from "../../Blocks/ListEventBlock";
import ContactsListBlock from "../../Blocks/ContactsListBlock";

class EventList extends React.Component{
    render() {
       return(
           <>
               <Header/>
               <div className="evzt-container app-content">
                   <div className={"app-left-side"}>
                       <ProfileBlock/>
                       <ContactsListBlock/>
                   </div>
                   <div className="principal-content">
                       {/*render content of each page*/}
                       <ListEventBlock />
                   </div>
               </div>
           </>)
    }
}

export default EventList
