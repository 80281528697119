import {Switch, Route, useHistory} from "react-router-dom";
import Inscription from "./Pages/Authentication/Inscription";
import Login from "./Pages/Authentication/Login";
import NotFound from "./Pages/Errors/404not-found";
import MySpace from "./Pages/MySpace";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import {getUserLang, isExpireToken, setTokenSession} from "./Utils/Common";
import EventList from "./Pages/Event/EventList";
import EventCreate from "./Pages/Event/Create";
import EventEdit from "./Pages/Event/Edit";
import EditProfile from "./Pages/EditProfile";
import Confirm from './Pages/Event/Confirm';
import ListGuest from "./Pages/Event/ListGuest";
import {withCookies} from "react-cookie";
import axios from "axios";
import ForgotPassword from "./Pages/Authentication/ForgotPassword";
import RenewPassword from "./Pages/Authentication/RenewPassword";
import Gallery from "./Pages/Event/Gallery";
import Home from "./Pages/Home";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import About from "./Pages/About";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {userSelector} from "./Store/Selectors";
import { API_BASE_URL, PUBLIC_URI_PATH } from './Utils/Defines';
import NewsLetterUnsubscribe from './Pages/Publics/NewsLetterUnsubscribe';
import Service from "./Pages/Service";
import Contact from "./Pages/Contact";
import MyContacts from "./Pages/Mobiles/MyContacts";
import MyEvents from "./Pages/Mobiles/MyEvents";
import MyEventsAttended from "./Pages/Mobiles/MyEventsAttended";
import GuestImageList from "./Pages/Event/GuestImageList";
import PictureViewer from "./Pages/PictureViewer";

const EvztRouter=(prop)=>{
    const {cookies}= prop;
    let token = cookies.get("ac");
    const history = useHistory();
    const {  t } = useTranslation();
    const user = useSelector(userSelector)
    axios.defaults.baseURL = API_BASE_URL.replaceAll('{_locale}',getUserLang());
    if(typeof token=="undefined" && user.isLogged){
        console.log("token ==>"+token, " USer Connecté",user)
        token=user.token.token;
       // console.log(token)
        cookies.set("ac",JSON.stringify(token),{maxAge:172800});
        //axios.defaults.headers.common['Authorization'] = "Bearer "+token.access_token;
    }
    //console.log(token !=="undefined",typeof token,user)
    if (typeof token !=="undefined" && token !=="undefined" ){
        console.log(token);
        setTokenSession(token)
        axios.defaults.headers.common['Authorization'] = "Bearer "+token.access_token;
        if(!isExpireToken(token) && history.location.pathname === "/login"){
           // console.log( history.location.pathname )
            history.push("/my-space")
        }
    }else if(!user.isLogged){
        if(PUBLIC_URI_PATH.findIndex((path) => path === history.location.pathname.split("/")[1])<0)
            history.push("/login")
    }
    
    return (
        <Switch>
            <PublicRoute exact path="/" component={Home} />
            <PublicRoute exact path="/display-picture/:eventName/" component={PictureViewer} />
            {/*<PrivateRoute exact path="/" component={ !isExpireToken(token) ? MySpace : Home}/>*/}
            <PrivateRoute path="/my-space" component={MySpace}/>
            <PrivateRoute path="/events/list" component={EventList}/>
            <PublicRoute path="/login" component={Login} />
            <PublicRoute path="/sign-up" component={Inscription} />
            <PublicRoute path="/forgot-password" component={ForgotPassword} />
            <PublicRoute path="/renew-password/:tk" component={RenewPassword} />
            <PrivateRoute path="/new-event" component={ () => <EventCreate t={t} />} />
            <PrivateRoute path="/edit-event/:id" component={EventEdit} />
            <PrivateRoute path="/my-profile" component={EditProfile} />
            <PrivateRoute path="/confirm" component={Confirm} />
            <PrivateRoute path="/event-guests/:id" component={ListGuest} />
            <PrivateRoute path="/gallery-event/:idEvent" component={Gallery} />
            <PrivateRoute path="/gallery-guest-event/:idEvent" component={GuestImageList} />
            <PublicRoute path="/privacy/policy" component={PrivacyPolicy} />
            <PublicRoute path="/about" component={About} />
            <PublicRoute path="/unsubscribe-newsletter" component={NewsLetterUnsubscribe} />
            <PublicRoute path="/contact" component={Contact} />
            <PublicRoute path="/service" component={Service} />
            <PrivateRoute key={"myContacts"} path="/my-contacts" component={MyContacts} />
            <PrivateRoute key={"myEvents"} path="/my-events" component={MyEvents} />
            <PrivateRoute key={"myEventsAttended"} path="/attended-events" component={MyEventsAttended} />
            <Route path="*" component={NotFound}/>
        </Switch>
    );
}

export default withCookies(EvztRouter)
