/**
 * Created by PhpStorm .
 * @author Brichard ZAFY<brichard.zafy@gmail.com>
 * Date: 30/06/2021
 * Time: 20:02
 */
import { Route, Redirect } from 'react-router-dom';
import {useSelector} from "react-redux";
import {userSelector} from "./Store/Selectors";
import {withCookies} from "react-cookie";

// handle the public routes
function PublicRoute({ component: Component, ...rest }) {
    const user = useSelector(userSelector)
    
    return (
        <Route
            {...rest}
            render={(props) => !user.isLogged ? <Component {...props} /> : <Redirect to={{ pathname: '/my-space' }} />}
        />
    )
}

export default withCookies(PublicRoute);