import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import {useForm,Controller} from "react-hook-form";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import ErrorMessageEvztField from "./ErrorMessageEvztField";
import {onSubmitFormEvent} from "../EventHandler/OnSubmitEvent";
import SpinerLoading from "./SpinerLoading";
import {connect} from "react-redux";
import {putToList, cleanMessageErr, setMessageErr, setFormEventSaved, refreshEvent} from "../Store/EventsReducer";
import ErrorFeedBack from "./ErrorFeedBack";
import {useParams} from "react-router-dom/cjs/react-router-dom";
import {getEventById} from "../Utils/Common";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import { HtmlTooltip } from "../Utils/Tooltips";
import { useRef, useEffect } from "react";

const FormEvent = (dispatch) => {
    let { id } = useParams();
    const {  t } = useTranslation();
    id = id??0;
    const {register, formState:{errors,isSubmitting}, handleSubmit,control,setValue,getValues} = useForm(
        {
            defaultValues: async () => {
                let data = {id:0,configInvtId:0,configShareId:0};
                dispatch.setFormEventSaved(false);
                if(id>0){
                    data = await getEventById(id).then(res =>  {
                        let defaultValues = {id:0,configInvtId:0,configShareId:0};
                        if(res.success){
                            const event = res.success.event
                            defaultValues = {
                                id:parseInt(id),
                                eventPublic:event.is_public,
                                eventName : event.name,
                                eventDate : event.date_event,
                                configInvtId:event.event_configurations[0].id,
                                emailSender : event.event_configurations[0].from,
                                eventSubject : event.event_configurations[0].subject,
                                eventInvitationMessage : event.event_configurations[0].mail_content,
                                eventInvitationSms : event.event_configurations[0].sms_content,
                                emailShareSender : event.event_configurations[1].from,
                                eventShareSubject : event.event_configurations[1].subject,
                                eventShareMessage : event.event_configurations[1].mail_content,
                                eventShareSms : event.event_configurations[1].sms_content,
                                configShareId: event.event_configurations[1].id
                            }
                        }
                        return defaultValues
                    }).catch(() => null)
                }
                return data
            }
        }
    );
    const feedBackErr = dispatch.events.err;
    const history = useHistory();
    const redirectPage = (path) => history.replace(path);
    if(dispatch.events.formSaved) {
        redirectPage("/")
    }
    const minDateEvent = new Date()
    minDateEvent.setDate(minDateEvent.getDate()+10)

    //***************** resizeObserver *******************//
const divRef = useRef(null);

useEffect(() => {
    const timeoutId = setTimeout(() => {
        const divElem = divRef.current;
    
        const resizeObserver = new ResizeObserver((entries) => {
            requestAnimationFrame(() => {
                for (const entry of entries) {
                    entry.target.style.width = entry.contentBoxSize[0].inlineSize + 1 + "px";
                }
            });
        });
        
        resizeObserver.observe(divElem);

        return () => {
            resizeObserver.unobserve(divElem);
        };
        
    }, 100);
    
    return () => clearTimeout(timeoutId);

}, []);

    //***************** ******************//

    return (
        <>
           <form method='post' onSubmit={handleSubmit((d)=>onSubmitFormEvent(d,dispatch))}>
               <ErrorFeedBack message={feedBackErr} classList={"evzt-alert error"} callBack={dispatch.cleanMessageErr}/>
                <div className="evzt-row">
                    <div className="evzt-form-lg-3">
                        <div className="evzt-form-group">
                            <HtmlTooltip title={"Explain"}>
                                <label htmlFor={"eventName"}>{t('formEventName')}</label>
                            </HtmlTooltip>
                            <input
                                defaultValue={getValues("eventName")}
                                type="text" id={"eventName"} className={"evzt-input-form"} placeholder={t('formEventNameDesc')}
                                name="name"
                                {...register("name", {required:t("formValidatorErrorMsgEmptyField")})}
                            />
                            <ErrorMessageEvztField  errors={errors} fieldName={"name"}/>
                        </div>
                    </div>
                    <div className="evzt-form-lg-3">
                        <div className="evzt-form-group">
                            <HtmlTooltip title={t('formEventPublicLabelDesc')}>
                                <label htmlFor={"eventPublic"}>{t('formEventPublicLabel')}</label>
                            </HtmlTooltip>
                            <input
                                checked={getValues("eventPublic")}
                                type="checkbox" id={"eventPublic"} className={"evzt-input-form"} placeholder={t('formEventPublicDesc')}
                                name="is_public"
                                {...register("is_public")}
                            />
                            <HtmlTooltip title={"Explain"}> 
                                <label className="custom-label">{t("formEventPublicLabel")}</label>
                            </HtmlTooltip>
                            <ErrorMessageEvztField errors={errors} fieldName={"is_public"} />
                        </div>
                    </div>
                    <div className="evzt-form-lg-3">
                        <div className="evzt-form-group" >
                            <label htmlFor={"eventDate"}>{t('formEventDate')}</label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Controller
                                    name={"eventDate"}
                                    control={control}
                                    rules={{ required: t("formValidatorErrorMsgEmptyField") }}
                                    defaultValue={ getValues("eventDate") ?? minDateEvent}
                                    render={({field}) => {
                                        return (
                                            <div id="evztForm" ref={divRef}>
                                                <DateTimePicker
                                                    disableMaskedInput={true}
                                                    mask="____-__-__ __:__"
                                                    inputFormat={"YYYY-MM-DD H:m"}
                                                    renderInput={(props) => {
                                                        return  <TextField {...props}  />
                                                    }}
                                                    placeholder={"Event date"}
                                                    onChange={ (newValue) => setValue(field.name,newValue) }
                                                    value={field.value}
                                                    minDate={getValues("eventDate") ?? minDateEvent}
                                                    />
                                                <ErrorMessageEvztField  errors={errors} fieldName={"eventDate"}/>
                                            </div>
                                        )
                                    }}
                                />

                            </LocalizationProvider>
                        </div>
                    </div>
                    <div className="evzt-12">
                        <fieldset className={"fieldset-form"}>
                            <legend>{t('formEventConfigurationInvt')}</legend>
                            <div className="evzt-row">
                                <div className="evzt-form-lg-4">
                                    <div className="evzt-form-group">
                                        <label htmlFor={"email"}>{t('formEventEmail')}</label>
                                        <input type="text" id={"email"}
                                               name="emailSender" className={"evzt-input-form"} placeholder={t('formEventEmailDesc')}
                                               {...register("emailSender", {required:t("formValidatorErrorMsgEmptyField")})}
                                        />
                                        <ErrorMessageEvztField  errors={errors} fieldName={"emailSender"}/>
                                    </div>
                                </div>
                                <div className="evzt-form-lg-4">
                                    <div className="evzt-form-group">
                                        <label htmlFor={"object"}>{t('formEventSubject')}</label>
                                        <input type="text" id={"object"} name={"eventSubject"} className={"evzt-input-form"}
                                               placeholder={t('formEventSubjectDesc')}
                                               {...register("eventSubject", {required:t("formValidatorErrorMsgEmptyField")})}
                                        />
                                        <ErrorMessageEvztField  errors={errors} fieldName={"eventSubject"}/>
                                    </div>
                                </div>
                            </div>
                            <div className={"editor evzt-form-group"}>
                                <label htmlFor={"eventInvitationMessage"}>{t('formEventMailInvtContent')}</label>
                                <Controller
                                    name={"eventInvitationMessage"}
                                    control={control}
                                    rules={{ required: t("formValidatorErrorMsgEmptyField") }}
                                    render={({field}) => {
                                        return (
                                            <>
                                                <CKEditor
                                                    data = {field.value}
                                                    placeholder={t('formEventMailInvtContent')}
                                                    editor={ClassicEditor}
                                                    onChange={(ev,editor)=> setValue(field.name,editor.getData()) }
                                                />
                                                <ErrorMessageEvztField  errors={errors} fieldName={field.name}/>
                                            </>
                                        )
                                    }}
                                />
                            </div>
                            <div className="evzt-form-group">
                                <label htmlFor={"eventInvitationSms"}>{t('formEventSMSInvtContent')}</label>
                                <textarea name={"eventInvitationSms"}  id={"eventInvitationSms"} className={"evzt-input-form"} placeholder={t('formEventSMSInvtContent')}
                                           {...register("eventInvitationSms", {required:t("formValidatorErrorMsgEmptyField")})}
                                />
                                <ErrorMessageEvztField  errors={errors} fieldName={"eventInvitationSms"}/>
                            </div>
                        </fieldset>
                    </div>
                    <div className="evzt-12">
                        <fieldset className={"fieldset-form"}>
                            <legend>{t('formEventConfigurationShare')}</legend>
                            <div className="evzt-row">
                                <div className="evzt-form-lg-4">
                                    <div className="evzt-form-group">
                                        <label htmlFor={"emailShareSender"}>{t('formEventShareEmail')}</label>
                                        <input name={"emailShareSender"} type="text" id={"emailShareSender"}
                                               className={"evzt-input-form"}
                                               placeholder={t("formEventEmailShareDesc")}
                                               {...register("emailShareSender", {required:t("formValidatorErrorMsgEmptyField")})}
                                        />
                                        <ErrorMessageEvztField  errors={errors} fieldName={"emailShareSender"}/>
                                    </div>
                                </div>
                                <div className="evzt-form-lg-4">
                                    <div className="evzt-form-group">
                                        <label htmlFor={"eventShareSubject"}>{t('formEventShareSubject')}</label>
                                        <input type="text" id={"eventShareSubject"}
                                               className={"evzt-input-form"} placeholder={t("formEventShareSubject")}
                                               {...register("eventShareSubject", {required:t("formValidatorErrorMsgEmptyField")})}
                                        />
                                        <ErrorMessageEvztField  errors={errors} fieldName={"eventShareSubject"}/>
                                    </div>
                                </div>
                            </div>

                            <div className={"editor evzt-form-group"}>
                                <Controller
                                    name={"eventShareMessage"}
                                    control={control}
                                    rules={{ required: t("formValidatorErrorMsgEmptyField") }}
                                    render={({field}) => {
                                        return (
                                            <>
                                                <CKEditor
                                                    data = {field.value}
                                                    placeholder={t('formEventShareMailShareContent')}
                                                    editor={ClassicEditor}
                                                    onChange={(ev,editor)=> setValue(field.name,editor.getData()) }
                                                />
                                                <ErrorMessageEvztField  errors={errors} fieldName={field.name}/>
                                            </>
                                        )
                                    }}
                                />
                            </div>

                            <div className="evzt-form-group">
                                <label htmlFor={"eventShareSms"}>{t('formEventShareSMSContent')}</label>
                                <textarea  id={"eventShareSms"} name={"eventShareSms"}
                                           className={"evzt-input-form"} placeholder={t('formEventShareSMSContent')}
                                           {...register("eventShareSms", {required:t("formValidatorErrorMsgEmptyField")})}
                                />
                                <ErrorMessageEvztField  errors={errors} fieldName={"eventShareSms"}/>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div className="evzt-row">
                    <div className="evzt-12 submit-event-form">
                        <button type={"submit"} > { id > 0? "Save" : "Create"} {isSubmitting && <SpinerLoading/>}</button>
                    </div>
                </div>
            </form>
        </>
    );
}
export const EventStore = connect(
    (state) =>({
        events : state.events
    }) ,
    (dispatch) => ({
        onSuccessEvent : event  => dispatch(putToList(event)),
        onUpdatedEvent : event  => dispatch(refreshEvent(event)),
        formFeedBack  : err => dispatch(setMessageErr(err)),
        setFormEventSaved   : loadingForm => dispatch(setFormEventSaved(loadingForm)),
        cleanMessageErr  : err => dispatch(cleanMessageErr(err))
    })
)(FormEvent)
export default EventStore;